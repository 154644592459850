<template>
  <div class="pano" :style="panoStyle">
    <div class="pano2">
      <div :id="'pano_'+randid" class="panoview" />
    </div>
    <div class="pano2 addon">
      <slot></slot>
      <div v-if="showProgress&&progress!=null" class="loadingbar">
        <div class="progress">
          <div :style="{width:progress+'%'}"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      panoId: String,
      ratio: Number,
      height: [Number, String],
      krpanoSettings: {
        type: Object,
        default: () => {
          return {
            //vars: { 'preview.type': 'grid(CUBE,64,64,512,0x000000,0x000000,0x000000);' },
            //consolelog: false,
            //html5: "auto",
            //initvars: {},
            //mwheel: true,
            //capturetouch: true,
            //focus: true,
            //mobilescale: 0.5,
            //fakedevice: "",//Available settings: "mobile", "tablet", "desktop".
            //passQueryParameters:false,
          }
        }
      },
      sceneVars: {
        type: Object,
        default: () => {
          return {
            //'view.fov': this.view.fov,
            //'view.vlookat': this.view.vLookAt,
            //'view.hlookat': this.view.hLookAt,
            //'view.fovmin': 70,
            //'view.fovmax': 140,
            //'view.maxpixelzoom': 2.0,
            //'view.fovtype': 'MFOV',
          }
        }
      },
      blend: String,
      onKrpanoReady: Function,
      onXmlComplete: Function,
      onLoadComplete: Function,
      showProgress: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        randid: Number(Math.random().toString().substr(3, 8) + Date.now()).toString(36),
        krpano: null,
        events: null,
        progress: null,
        loadPanoing: null,
        loading: false,
        waiting: null,
        //alive: true,
        funcNameList: [],
      }
    },
    watch: {
      panoId: function (newval, oldval) {
        if (newval != oldval) {
          this.setPano(newval)
        }
      },
    },
    computed: {
      paddingBottom: function () {
        if (this.ratio) {
          return (1 / this.ratio) * 100
        } else {
          return 75
        }
      },
      panoStyle: function () {
        if (this.height) {
          if (typeof this.height === 'number') {
            return { height: this.height + 'px', 'padding-bottom': 0 }
          } else if (typeof this.height === 'string') {
            return { height: this.height, 'padding-bottom': 0 }
          }
          return {}
        } else {
          return { 'padding-bottom': this.paddingBottom + '%' }
        }
      }
    },
    mounted() {
      this.$remoteLoad(`${this.$store.state.settings.filesUrl}${process.env.KrpanoPath || window.global.KRPANO_PATH}/krpano.js`).then(() => {
        this.randid = Number(Math.random().toString().substr(3, 8) + Date.now()).toString(36)
        setTimeout(() => {
          this.create_krpano()
        }, 10)
      })
      //this.setPano()
    },
    created() {
    },
    destroyed() {
      console.log('krpano destroyed')
      this.cleanScene()
      //this.alive = false
      if (typeof removepano === 'function') {
        removepano('pano_' + this.randid)
        console.log('krpano removepano')
      }
      for (var i in this.funcNameList) {
        window[this.funcNameList[i]] = null
      }
    },
    methods: {
      create_krpano() {
        if (typeof embedpano === 'function' && document.getElementById('pano_' + this.randid)) {
          console.log('create_krpano', 'pano_' + this.randid)
          var settings = {
            swf: `${this.$store.state.settings.filesUrl}${process.env.KrpanoPath || window.global.KRPANO_PATH}/krpano.swf`,
            target: 'pano_' + this.randid,
            id: 'krpano_' + this.randid,
            //html5: 'only+css3d',
            onready: (krpano) => {
              this.krpano = krpano
              this.events = krpano.get('events')
              //window.krpanoevents = this.events
              if (this.onKrpanoReady) {
                this.onKrpanoReady(krpano)
              }
              this.$emit('krpanoReady', krpano)
              this.setPano()
            },
            onerror: (e) => {
              console.error('krpano加载错误', e)
            },
            ...this.krpanoSettings,
            vars: {
              'preview.type': 'grid(CUBE,64,64,512,0xCCCCCC,0x000000,0x999999);',
              ...this.krpanoSettings.vars,
            },
          }
          console.log(settings)
          embedpano(settings)
        } else {
          console.error('错误')
        }
        // else if (this.alive) {
        //  console.log(this.$el.parentNode)
        //  setTimeout(() => {
        //    this.create_krpano()
        //  }, 10)
        //}
      },
      setPano(panoId, settings) {
        if (this.loading) {
          this.waiting = { panoId, settings }
        }
        if (!panoId) {
          panoId = this.panoId
        }
        this.loading = true;
        console.log('setPano', panoId, this.krpano)
        this.$emit('setingPano', panoId)
        var { onXmlComplete, onLoadComplete, vars, } = settings || {}
        const _this = this
        //if (this.loadPanoing) {
        //  return
        //}
        //if (this.progress) {
        //  return
        //}
        if (!panoId) {
          return
        }
        if (this.krpano) {
          this.progress = 0
          var progressInterval = setInterval(() => {
            this.progress = (this.krpano.get('progress.progress') * 100)
            if (this.progress >= 100) {
              setTimeout(() => {
                this.progress = null
                clearInterval(progressInterval)
              }, 500)
            }
          }, 1)
          var sceneVars = this.sceneVars
          for (var i in vars) {
            sceneVars[i] = vars[i]
          }
          //var _vars = `events.onloadcomplete=js(${this.getFuncName(() => {
          //  if (onLoadComplete) {
          //    onLoadComplete()
          //  }
          //  if (this.onLoadComplete) {
          //    this.onLoadComplete()
          //  }
          //})}())&events.onxmlcomplete=js(${this.getFuncName(() => {
          //  if (onXmlComplete) {
          //    onXmlComplete()
          //  }
          //  if (this.onXmlComplete) {
          //    this.onXmlComplete()
          //  }
          //})}())`
          this.events.onloadcomplete = `js(${this.getFuncName(() => {
            if (onLoadComplete) {
              onLoadComplete()
            }
            if (this.onLoadComplete) {
              this.onLoadComplete()
            }
            this.loading = false
            if (this.waiting) {
              this.setPano(this.waiting.panoId, this.waiting.settings)
              this.waiting = null
            }
          })}())`
          this.events.onxmlcomplete = `js(${this.getFuncName(() => {
            console.log('onxmlcomplete')
            if (onXmlComplete) {
              onXmlComplete()
            }
            if (this.onXmlComplete) {
              this.onXmlComplete()
            }
          })}())`
          var _vars = ''
          for (var i in sceneVars) {
            if (_vars != '') {
              _vars += '&'
            }
            _vars += `${i}=${sceneVars[i]}`
          }
          this.krpano.call(`loadpanoscene(${this.$store.state.settings.filesUrl}/Xml/${panoId}.xml,Scene_${panoId},${_vars},null,${this.blend || 'null'});`)
          console.log(panoId, 'loadpanoscene', onXmlComplete, _vars, this.blend)
          console.log(`loadpanoscene(${this.$store.state.settings.filesUrl}/Xml/${panoId}.xml,Scene_${panoId},${_vars},null,${this.blend || 'null'});`)
          setTimeout(() => { console.log(this.krpano.get('view').vlookat) }, 1)
        }
        //else if (this.alive) {
        //  this.loadPanoing = setTimeout(() => {
        //    _this.loadPanoing = null
        //    //_this.setPano(panoId, settings)
        //  }, 10)
        //}
      },
      cleanScene() {
        if (!this.krpano) return
        this.krpano.call(`loadxml(<krpano><preview type="grid(cube,64,64,512,0xCCCCCC,0x000000,0x999999);" /><view hlookat="0" vlookat="0" fovtype="VFOV" fov="90" fovmin="30" fovmax="150" /></krpano>)`)
      },
      getFuncName(func, name) {
        var funcname = null
        if (name) {
          funcname = this.randid + Math.random().toString().substr(3, 8) + name
        } else {
          funcname = this.randid + Math.random().toString().substr(3, 8)
        }
        window[funcname] = func
        this.funcNameList.push(funcname)
        return funcname
      }
    }
  }
</script>
<style scoped>
  .pano {
    height: 0;
    width: 100%;
    padding-bottom: 75%;
    position: relative;
  }

    .pano .pano2 {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

      .pano .pano2.addon {
        pointer-events: none;
        overflow: hidden;
      }

        .pano .pano2.addon > * {
          pointer-events: auto
        }

    .pano .panoview {
      width: 100%;
      height: 100%;
      background-color: #000;
    }

    .pano .loadingbar {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      /*font-size: 0;*/
    }

      .pano .loadingbar .progress {
        width: 100%;
        height: 3px;
        background-color: transparent;
        opacity: 1;
        display: block;
      }

        .pano .loadingbar .progress > * {
          background-color: #fff;
          height: 100%;
        }
</style>
