import Vue from 'vue'
import Router from 'vue-router'
import mainAuth from './auth';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "default" */ './views/Default.vue'),
      meta: {
        authName: mainAuth.authName
      },
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
          meta: { authName: mainAuth.authName, title: '主页' }
        },
        {
          path: 'Help',
          name: 'Help',
          component: () => import(/* webpackChunkName: "help" */ './views/Help.vue'),
          meta: { authName: mainAuth.authName, title: '帮助' }
        },
        {
          path: 'Avatar',
          name: 'Avatar',
          component: () => import(/* webpackChunkName: "avatar" */ './views/Account/Avatar.vue'),
          meta: { authName: mainAuth.authName, title: '修改头像' }
        },
        {
          path: 'Profile',
          name: 'Profile',
          component: () => import(/* webpackChunkName: "profile" */ './views/Account/View.vue'),
          meta: { authName: mainAuth.authName, title: '我的资料' }
        },
        {
          path: 'Profile/Edit',
          name: 'ProfileEdit',
          component: () => import(/* webpackChunkName: "profile-edit" */ './views/Account/Profile.vue'),
          meta: { authName: mainAuth.authName, title: '修改资料' }
        },
        {
          path: 'Account/ChangePassword',
          name: 'ChangePassword',
          component: () => import(/* webpackChunkName: "change-password" */ './views/Account/ChangePassword.vue'),
          meta: { authName: mainAuth.authName, title: '修改密码' }
        },
        {
          path: 'Account/ConfirmPhoneNumber',
          name: 'ConfirmPhoneNumber',
          component: () => import(/* webpackChunkName: "confirm-phone-number" */ './views/Account/ConfirmPhoneNumber.vue'),
          meta: { authName: mainAuth.authName, title: '确认手机号' }
        },
        {
          path: 'Account/ConfirmEmail',
          name: 'ConfirmEmail',
          component: () => import(/* webpackChunkName: "confirm-email" */ './views/Account/ConfirmEmail.vue'),
          meta: { authName: mainAuth.authName, title: '确认邮件' }
        },
        {
          path: 'Pano',
          name: 'PanoList',
          component: () => import(/* webpackChunkName: "pano-list" */ './views/Pano/List.vue'),
          meta: { authName: mainAuth.authName, title: '我的全景' }
        },
        {
          path: 'Weixin/Upload',
          name: 'WeixinUpload',
          component: () => import(/* webpackChunkName: "weixin-upload" */ './views/Weixin/Upload.vue'),
          meta: { authName: mainAuth.authName, title: '微信图片' }
        },
        {
          path: 'Weixin/Recorder',
          name: 'WeixinRecorder',
          component: () => import(/* webpackChunkName: "weixin-recorder" */ './views/Weixin/Recorder.vue'),
          meta: { authName: mainAuth.authName, title: '微信录音' }
        },
        {
          path: 'Pano/Edit/:panoId',
          name: 'PanoEdit',
          component: () => import(/* webpackChunkName: "pano-edit" */ './views/Pano/Edit.vue'),
          meta: { authName: mainAuth.authName, title: '全景编辑' }
        }, {
          path: 'HotspotIcon',
          name: 'HotspotIcon',
          component: () => import(/* webpackChunkName: "pano-edit" */ './views/Pano/HotspotIcon.vue'),
          meta: { authName: mainAuth.authName, title: '热点图标' }
        },
        {
          path: 'Blog',
          name: 'BlogList',
          component: () => import(/* webpackChunkName: "blog-list" */ './views/Blog/List.vue'),
          meta: { authName: mainAuth.authName, title: '我的博文' }
        },
        {
          path: 'Blog/Create',
          name: 'BlogCreate',
          component: () => import(/* webpackChunkName: "blog-create" */ './views/Blog/Edit.vue'),
          meta: { authName: mainAuth.authName, title: '博文创建' }
        },
        {
          path: 'Blog/Edit/:blogId',
          name: 'BlogEdit',
          component: () => import(/* webpackChunkName: "blog-edit" */ './views/Blog/Edit.vue'),
          meta: { authName: mainAuth.authName, title: '博文编辑' }
        },
        {
          path: 'Blog/:blogId',
          name: 'BlogView',
          component: () => import(/* webpackChunkName: "blog-view" */ './views/Blog/View.vue'),
          meta: { authName: mainAuth.authName, title: '博文查看' }
        },
        {
          path: 'Round',
          name: 'RoundList',
          component: () => import(/* webpackChunkName: "tour-list" */ './views/Round/List.vue'),
          meta: { authName: mainAuth.authName, title: '我的环绕' }
        },
        {
          path: 'Tour',
          name: 'TourList',
          component: () => import(/* webpackChunkName: "tour-list" */ './views/Tour/List.vue'),
          meta: { authName: mainAuth.authName, title: '我的漫游' }
        },
        {
          path: 'Tour/Create',
          name: 'TourCreate',
          component: () => import(/* webpackChunkName: "tour-create" */ './views/Tour/Edit.vue'),
          meta: { authName: mainAuth.authName, title: '漫游创建' }
        },
        {
          path: 'Tour/Edit/:tourId',
          name: 'TourEdit',
          component: () => import(/* webpackChunkName: "tour-edit" */ './views/Tour/Edit.vue'),
          meta: { authName: mainAuth.authName, title: '漫游编辑' }
        },
        {
          path: 'Tour/:tourId',
          name: 'TourView',
          component: () => import(/* webpackChunkName: "tour-view" */ './views/Tour/View.vue'),
          meta: { authName: mainAuth.authName, title: '漫游查看' }
        },
        {
          path: 'Pay/Tour',
          name: 'TourPay',
          component: () => import(/* webpackChunkName: "pay" */ './views/Tour/Pay.vue'),
          meta: { authName: mainAuth.authName, title: '支付' }
        },
        {
          path: 'Buy/:productId',
          name: 'Buy',
          component: () => import(/* webpackChunkName: "buy" */ './views/Buy.vue'),
          meta: { authName: mainAuth.authName, title: '购买' }
        },

        {
          path: 'Tag',
          name: 'TagList',
          component: () => import(/* webpackChunkName: "tag-list" */ './views/Tag.vue'),
          meta: { authName: mainAuth.authName, title: '标签管理' }
        },
        {
          path: 'Order',
          name: 'OrderList',
          component: () => import(/* webpackChunkName: "order-list" */ './views/Order/List.vue'),
          meta: { authName: mainAuth.authName, title: '订单管理' }
        },
        {
          path: 'Notice',
          name: 'NoticeList',
          component: () => import(/* webpackChunkName: "notice-list" */ './views/Notice/List.vue'),
          meta: { authName: mainAuth.authName, title: '通知管理' }
        },
        {
          path: 'Comment',
          name: 'CommentList',
          component: () => import(/* webpackChunkName: "comment-list" */ './views/Comment/List.vue'),
          meta: { authName: mainAuth.authName, title: '留言管理' }
        },
        {
          path: 'Chat/:userName',
          name: 'Chat',
          component: () => import(/* webpackChunkName: "message" */ './views/Message/Message.vue'),
          meta: { authName: mainAuth.authName, title: '聊天会话' }
        },
        {
          path: 'Chat',
          name: 'MessageList',
          component: () => import(/* webpackChunkName: "message-list" */ './views/Message/List.vue'),
          meta: { authName: mainAuth.authName, title: '站内消息' }
        },
        {
          path: 'Lib',
          name: 'LibList',
          component: () => import(/* webpackChunkName: "lib-list" */ './views/Lib/List.vue'),
          meta: { authName: mainAuth.authName, title: '素材库' }
        },
        {
          path: 'Lib/Recorder',
          name: 'Recorder',
          component: () => import(/* webpackChunkName: "recorder" */ './views/Lib/Recorder.vue'),
          meta: { authName: mainAuth.authName, title: '录音' }
        },
        {
          path: 'NoAccess',
          name: 'NoAccess',
          component: () => import(/* webpackChunkName: "noaccess" */ './views/NoAccess.vue'),
          meta: { authName: mainAuth.authName, title: '无权访问' }
        },
      ]
    },
    {
      path: '/',
      component: () => import(/* webpackChunkName: "group-layout" */ './views/ListLayout.vue'),
      meta: {
        authName: mainAuth.authName
      },
      children: [
        {
          path: 'Group',
          name: 'GroupIndex',
          component: () => import(/* webpackChunkName: "group-index" */ './views/Group/Index.vue'),
          meta: {
            authName: mainAuth.authName
          },
        },
      ]
    },
    {
      path: '/Group/:groupId',
      component: () => import(/* webpackChunkName: "group-layout" */ './views/GroupLayout.vue'),
      meta: {
        authName: mainAuth.authName
      },
      children: [
        {
          path: '',
          name: 'GroupHome',
          component: () => import(/* webpackChunkName: "group-home" */ './views/Group/Home.vue'),
          meta: { authName: mainAuth.authName, title: '主页' }
        },
        {
          path: 'Help',
          name: 'GroupHelp',
          component: () => import(/* webpackChunkName: "group-help" */ './views/Help.vue'),
          meta: { authName: mainAuth.authName, title: '帮助' }
        },
        {
          path: 'Edit',
          name: 'GroupEdit',
          component: () => import(/* webpackChunkName: "group-edit" */ './views/Group/Edit.vue'),
          meta: { authName: mainAuth.authName, title: '设置' }
        },
        {
          path: 'View',
          name: 'GroupView',
          component: () => import(/* webpackChunkName: "group-view" */ './views/Group/View.vue'),
          meta: { authName: mainAuth.authName, title: '查看' }
        },
        {
          path: 'Pano',
          name: 'GroupPanoList',
          component: () => import(/* webpackChunkName: "group-pano-list" */ './views/Group/Pano/List.vue'),
          meta: { authName: mainAuth.authName, title: '全景列表' }
        },
        {
          path: 'Pano/:panoId',
          name: 'GroupPanoEdit',
          component: () => import(/* webpackChunkName: "group-pano-edit" */ './views/Group/Pano/Edit.vue'),
          meta: { authName: mainAuth.authName, title: '全景编辑' }
        },
        {
          path: 'Tour',
          name: 'GroupTourList',
          component: () => import(/* webpackChunkName: "group-pano-list" */ './views/Group/Tour/List.vue'),
          meta: { authName: mainAuth.authName, title: '漫游列表' }
        },
        {
          path: 'Tour/Create',
          name: 'GroupTourCreate',
          component: () => import(/* webpackChunkName: "group-pano-edit" */ './views/Group/Tour/Edit.vue'),
          meta: { authName: mainAuth.authName, title: '漫游创建' }
        },
        {
          path: 'Tour/:tourId',
          name: 'GroupTourEdit',
          component: () => import(/* webpackChunkName: "group-pano-edit" */ './views/Group/Tour/Edit.vue'),
          meta: { authName: mainAuth.authName, title: '漫游编辑' }
        },
        {
          path: 'HotspotIcon',
          name: 'GroupHotspotIcon',
          component: () => import(/* webpackChunkName: "group-pano-edit" */ './views/Pano/HotspotIcon.vue'),
          meta: { authName: mainAuth.authName, title: '热点图标' }
        },
        {
          path: 'Lib',
          name: 'GroupLib',
          component: () => import(/* webpackChunkName: "group-pano-edit" */ './views/Lib/List.vue'),
          meta: { authName: mainAuth.authName, title: '素材库' }
        },
        {
          path: 'Tag',
          name: 'GroupTag',
          component: () => import(/* webpackChunkName: "group-pano-edit" */ './views/Tag.vue'),
          meta: { authName: mainAuth.authName, title: '标签管理' }
        },

      ]
    },
    {
      path: '/Public',
      component: () => import(/* webpackChunkName: "public-layout" */ './views/PublicLayout.vue'),
      meta: {
        authName: mainAuth.authName
      },
      children: [
        {
          path: '',
          name: 'PublicHome',
          component: () => import(/* webpackChunkName: "public-home" */ './views/Public/Home.vue'),
          meta: { authName: mainAuth.authName, title: '主页' }
        },
        {
          path: 'Pano/:category',
          name: 'PublicCategoryPanoList',
          component: () => import(/* webpackChunkName: "street-list" */ './views/Public/Pano/List.vue'),
          meta: { authName: mainAuth.authName, title: '街景管理' }
        },
        {
          path: 'Pano/:category/Edit/:panoId',
          name: 'PublicCategoryPanoEdit',
          component: () => import(/* webpackChunkName: "street-edit" */ './views/Public/Pano/Edit.vue'),
          meta: { authName: mainAuth.authName, title: '街景编辑' }
        },
        {
          path: 'Pano',
          name: 'PublicPanoList',
          component: () => import(/* webpackChunkName: "street-list" */ './views/Public/Pano/List.vue'),
          meta: { authName: mainAuth.authName, title: '全景管理' }
        },
        {
          path: 'Pano/Edit/:panoId',
          name: 'PublicPanoEdit',
          component: () => import(/* webpackChunkName: "street-edit" */ './views/Public/Pano/Edit.vue'),
          meta: { authName: mainAuth.authName, title: '全景编辑' }
        },
        {
          path: 'Tour/:category',
          name: 'PublicTourList',
          component: () => import(/* webpackChunkName: "street-list" */ './views/Public/Tour/List.vue'),
          meta: { authName: mainAuth.authName, title: '漫游管理' }
        },
        {
          path: 'Tour/:category/Edit/:tourId',
          name: 'PublicTourEdit',
          component: () => import(/* webpackChunkName: "street-edit" */ './views/Public/Tour/Edit.vue'),
          meta: { authName: mainAuth.authName, title: '漫游编辑' }
        },
        {
          path: 'Tour/:category/Create',
          name: 'PublicTourCreate',
          component: () => import(/* webpackChunkName: "street-edit" */ './views/Public/Tour/Edit.vue'),
          meta: { authName: mainAuth.authName, title: '漫游创建' }
        },
        {
          path: 'HotspotIcon',
          name: 'PublicHotspotIcon',
          component: () => import(/* webpackChunkName: "group-pano-edit" */ './views/Pano/HotspotIcon.vue'),
          meta: { authName: mainAuth.authName, title: '热点图标' }
        },
        {
          path: 'Lib',
          name: 'PublicLib',
          component: () => import(/* webpackChunkName: "group-pano-edit" */ './views/Lib/List.vue'),
          meta: { authName: mainAuth.authName, title: '素材库' }
        },
        {
          path: 'Tag',
          name: 'PublicTag',
          component: () => import(/* webpackChunkName: "group-pano-edit" */ './views/Tag.vue'),
          meta: { authName: mainAuth.authName, title: '标签管理' }
        },

      ]
    },

    {
      path: '/Pano/:panoId',
      name: 'PanoFullScreen',
      component: () => import(/* webpackChunkName: "pano-full-screen" */ './views/PanoFullScreen.vue'),
      meta: { authName: mainAuth.authName, title: '全景查看' }
    },
    //{
    //    path: '*',
    //    component: () => import(/* webpackChunkName: "default" */ './views/Default.vue'),
    //    children: [
    //        {
    //            path: '',
    //            name: 'NotFound',
    //            component: () => import(/* webpackChunkName: "not-found" */ './views/NotFound.vue'),
    //            meta: { title: '404 文件未找到' }
    //        },
    //    ]
    //},
  ],
  //linkExactActiveClass: 'active'
})

mainAuth.useRouter(router);

export default router;
