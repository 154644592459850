export default {
  transform: function (wgLat, wgLng) {
    const location = new Location()
    if (!isInsideChina(wgLat, wgLng)) {
      location.lat = wgLat
      location.lng = wgLng
      return location
    }
    let dLat = transformLat(wgLng - 105.0, wgLat - 35.0)
    let dLng = transformLng(wgLng - 105.0, wgLat - 35.0)
    const radLat = wgLat / 180.0 * Math.PI
    let magic = Math.sin(radLat)
    magic = 1 - ee * magic * magic
    const sqrtMagic = Math.sqrt(magic)
    dLat = dLat * 180.0 / (a * (1 - ee) / (magic * sqrtMagic) * Math.PI)
    dLng = dLng * 180.0 / (a / sqrtMagic * Math.cos(radLat) * Math.PI)
    location.lat = wgLat + dLat
    location.lng = wgLng + dLng
    return location
  },
  offset: function (wgLat, wgLng) {
    const location = new Location()
    if (!isInsideChina(wgLat, wgLng)) {
      location.lat = 0
      location.lng = 0
      return location
    }
    let dLat = transformLat(wgLng - 105.0, wgLat - 35.0)
    let dLng = transformLng(wgLng - 105.0, wgLat - 35.0)
    const radLat = wgLat / 180.0 * Math.PI
    let magic = Math.sin(radLat)
    magic = 1 - ee * magic * magic
    const sqrtMagic = Math.sqrt(magic)
    dLat = dLat * 180.0 / (a * (1 - ee) / (magic * sqrtMagic) * Math.PI)
    dLng = dLng * 180.0 / (a / sqrtMagic * Math.cos(radLat) * Math.PI)
    location.lat = dLat
    location.lng = dLng
    return location
  },
  restore: function (wgLat, wgLng) {
    const location = new Location()
    if (!isInsideChina(wgLat, wgLng)) {
      location.lat = wgLat
      location.lng = wgLng
      return location
    }
    let dLat = transformLat(wgLng - 105.0, wgLat - 35.0)
    let dLng = transformLng(wgLng - 105.0, wgLat - 35.0)
    const radLat = wgLat / 180.0 * Math.PI
    let magic = Math.sin(radLat)
    magic = 1 - ee * magic * magic
    const sqrtMagic = Math.sqrt(magic)
    dLat = dLat * 180.0 / (a * (1 - ee) / (magic * sqrtMagic) * Math.PI)
    dLng = dLng * 180.0 / (a / sqrtMagic * Math.cos(radLat) * Math.PI)
    location.lat = wgLat - dLat
    location.lng = wgLng - dLng
    return location
  },
  /// <summary>
  /// 计算两个经纬度之间的距离
  /// </summary>
  getDistance: function (lat1, lng1, lat2, lng2) {
    const EARTH_RADIUS = 6378.137//地球半径
    function rad(d) {
      return d * Math.PI / 180.0
    }

    const radLat1 = rad(lat1)
    const radLat2 = rad(lat2)
    const a = radLat1 - radLat2
    const b = rad(lng1) - rad(lng2)

    let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)))
    s = s * EARTH_RADIUS
    s = Math.round(s * 10000) / 10000
    return s
  },
  // 疑似距离过近方向有误
  // getAngle: function (lat1, lng1, lat2, lng2) {
  //   var x = Math.sin(lng2 - lng1) * Math.cos(lat2)
  //   var y = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1)
  //   var a = Math.atan2(x, y) * 180 / Math.PI
  //   return a > 0 ? a : a + 360
  // }
  getAngle: function (lat1, lng1, lat2, lng2) {
    return bearing({ 'latitude': lat1, 'longitude': lng1 }, { 'latitude': lat2, 'longitude': lng2 })
  }

}
//中国GPS坐标转换
const a = 6378245.0
const ee = 0.00669342162296594323
function Location() {
  this.lat = 0
  this.lng = 0
}

function outOfChina(lat, lng) {
  if (lng < 72.004 || lng > 137.8347) { return true }
  if (lat < 0.8293 || lat > 55.8271) { return true }
  return false
}

function transformLat(x, y) {
  let ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x))
  ret += (20.0 * Math.sin(6.0 * x * Math.PI) + 20.0 * Math.sin(2.0 * x * Math.PI)) * 2.0 / 3.0
  ret += (20.0 * Math.sin(y * Math.PI) + 40.0 * Math.sin(y / 3.0 * Math.PI)) * 2.0 / 3.0
  ret += (160.0 * Math.sin(y / 12.0 * Math.PI) + 320 * Math.sin(y * Math.PI / 30.0)) * 2.0 / 3.0
  return ret
}

function transformLng(x, y) {
  let ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x))
  ret += (20.0 * Math.sin(6.0 * x * Math.PI) + 20.0 * Math.sin(2.0 * x * Math.PI)) * 2.0 / 3.0
  ret += (20.0 * Math.sin(x * Math.PI) + 40.0 * Math.sin(x / 3.0 * Math.PI)) * 2.0 / 3.0
  ret += (150.0 * Math.sin(x / 12.0 * Math.PI) + 300.0 * Math.sin(x / 30.0 * Math.PI)) * 2.0 / 3.0
  return ret
}

//判断经纬度是否在中国
function isInsideChina(lat, lng) {
  const inChina = [
    [49.220400, 79.850000, 42.700000, 96.330000],
    [53.575000, 109.687200, 39.850000, 135.000200],
    [42.700000, 73.500000, 29.529700, 124.40000],
    [29.529700, 82.968400, 26.850000, 97.500000],
    [29.529700, 97.500000, 21.150000, 124.367395],
    [21.150000, 107.975793, 17.871542, 119.50000],
    [17.871542, 110.331275, 11.855000, 119.03800],
    [11.855000, 116.511180, 9.403718, 117.990000],
    [11.855000, 109.364478, 6.700000, 116.51118],
    [6.700000, 108.90000, 3.700000, 113.325149]
  ]

  const outChina = [
    [25.398623, 119.921265, 21.785006, 122.497559], //台湾
    [22.384000, 101.865200, 20.400000, 106.665000],
    [21.542200, 106.452500, 20.487800, 108.051000],
    [54.000000, 109.032300, 50.025700, 119.127000],
    [54.000000, 127.456800, 49.557400, 135.000200],
    [44.892200, 131.266200, 42.569200, 135.000200],
    [50.105264, 109.474341, 45.485678, 115.429031],
    [22.510000, 113.815660, 22.150000, 114.454000], //香港
    [22.554000, 114.144000, 22.510000, 114.453310], //香港
    [50.050000, 91.750000, 45.250000, 115.530000],
    [49.252219, 91.061255, 45.300519, 96.378738],
    [27.516418, 94.126541, 25.872335, 98.570611],
    [23.063017, 97.021539, 21.118694, 99.109041],
    [34.527796, 73.082330, 29.509766, 78.344881],
    [23.856465, 97.010500, 23.040000, 98.658575]
  ]
  for (let i = 0; i < 10; i++) {
    if (lat <= inChina[i][0] && lat >= inChina[i][2] && lng >= inChina[i][1] && lng <= inChina[i][3]) {
      for (let j = 0; j < 15; j++) {
        if (lat <= outChina[j][0] && lat >= outChina[j][2] && lng >= outChina[j][1] && lng <= outChina[j][3]) {
          return false
        }
      }
      return true
    }
  }
  return false
}
/*
 * 计算两点对于正北方向的朝向角度 [0,360]
 * @param {*} start format:{'latitude': 30, 'longitude': 120 }
 * @param {*} end
 */
function bearing(start, end) {
  let rad = Math.PI / 180,
    lat1 = start.latitude * rad,
    lat2 = end.latitude * rad,
    lon1 = start.longitude * rad,
    lon2 = end.longitude * rad;
  const a = Math.sin(lon2 - lon1) * Math.cos(lat2);
  const b = Math.cos(lat1) * Math.sin(lat2) -
    Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);

  return radiansToDegrees(Math.atan2(a, b));
}

/*
 * 弧度转换为角度
 */
function radiansToDegrees(radians) {
  const degrees = radians % (2 * Math.PI);
  return degrees * 180 / Math.PI;
}