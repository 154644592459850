<template lang="html">
  <!-- Modal -->
  <div class="modal fade" :id="bsModal" tabindex="-1" role="dialog" :aria-labelledby="bsModal+'Label'" data-toggle="modal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header p-2">
          <slot name="header">
            <h4 class="modal-title" :id="bsModal+'Label'">title</h4>
          </slot>
          <button type="button" class="close" @click="hide" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body p-1">
          <slot>
            description
          </slot>
        </div>
        <div class="modal-footer justify-content-between p-2">
          <slot name="footer">
            <button type="button" class="btn btn-default" @click="hide">关闭</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // const ModalTypes = ['modal-primary', 'modal-success', 'modal-info', 'modal-warning', 'modal-danger']
  import uuid from 'uuid'
  export default {
    mounted() {
      this.bsModal = this.idname + 'modal';
    },
    data() {
      return {
        idname: 'Modal_' + uuid.v1(),
        bsModal: null,
      }
    },
    props: {
      onhide: Function
    },
    methods: {
      show() {
        $('#' + this.bsModal).modal('show')
      },
      hide() {
        var _this = this
        this.$emit('hide')
        if (this.onhide) {
          this.onhide()
        }
        $('#' + this.bsModal).on('hidden.bs.modal', function (e) {
          _this.checkHasMadel()
        })
        $('#' + this.bsModal).modal('hide')
      },
      checkHasMadel() {
        if ($('.modal.in').length >= 1) {
          $('body').addClass('modal-open');
        }
      }
    }
  }
</script>

<style lang="css">
</style>
