<template>
  <label :class="pclass?pclass:'btn btn-primary'">
    <slot>
      选择文件
    </slot>
    <input @change="addFile" :multiple="multiple" type="file" :name="name" :accept="inputAccetp" style="position:absolute; clip:rect(0 0 0 0);" />
  </label>

</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import md5 from "js-md5";
  export default {
    name: 'uploadBigFile',
    props: {
      name: String,
      pclass: String,
      action: {
        type: String,
        required: true
      },
      fileList: {
        type: Array,
        default: []
      },
      param: Object,
      allowExtensions: RegExp,
      inputAccetp: String,
      multiple: Boolean,
      limit: Number,
      autoUpload: Boolean,
      maxSize: Number,
      minSize: Number,
      partSize: Number,
      maxUpper: {
        type: Number,
        default: 1
      },
      beforeCheck: Function,
      //onChange: Function,
      //onProgress: Function,
      //onSuccess: Function,
      //onFailed: Function,
      //onFinished: Function,
      //onCheckingCount: Function,
    },
    data: function () {
      return {
        uping: 0,
        waitingList: [],
        finish: true,
        checkingCount: 0,
      };
    },
    computed: {
      ...mapGetters([
        'setting',
        'currentUser'
      ]),
    },
    methods: {
      ...mapActions([
        'getUser'
      ]),
      addFile(e) {
        var fs = e.target.files;
        for (let index = 0; index < fs.length; index++) {
          var limit = this.limit || 10
          var l = this.fileList.length;
          console.log(l + this.checkingCount + 1, limit)
          if ((l + this.checkingCount + 1) > limit) {
            console.log('超过上传上限')
            this.$message({
              message: fs[index].name + " 超过上传上限",
              type: 'error',
              zIndex: 9999,
              //showClose: true
            })
            continue;
          }
          const file = fs[index];
          this.checkingCount++
          this.$emit('checkingCount', this.checkingCount)
          this.checkFile(file).then((t) => {
            if (t) {
              this.getFileId(file).then(id => {
                if (this.findFile(id) == null) {
                  this.getPosition(id).then(p => {
                    console.log('getPosition', p)
                    let position = p ?? 0
                    let d = {
                      id,
                      name: file.name,
                      size: file.size,
                      lastModified: file.lastModified,
                      lastTime: new Date(),
                      position: position,
                      loaded: position,
                      percent: Number((position / file.size * 100).toFixed(2)),
                      file,
                      status: 'ready',
                    }
                    this.checkingCount--
                    this.$emit('checkingCount', this.checkingCount)
                    this.fileList.push(d);
                    this.$emit('change', d)
                    console.log(d.name, d.id)
                    if (file.size < 100 * 1024 * 1024) {
                      makePreview(file, 200, 200).then(({ url }) => {
                        d.preview = url;
                        this.$emit('change', d);
                      }).catch((err) => { console.error(err) })
                    }
                  }).catch((err) => {
                    this.checkingCount--
                    this.$emit('checkingCount', this.checkingCount)
                    this.$message({
                      message: file.name + " " + err.message,
                      type: 'error',
                      zIndex: 9999,
                      //showClose: true
                    })
                  })
                } else {
                  this.checkingCount--
                  this.$emit('checkingCount', this.checkingCount)
                  this.$message({
                    message: `${file.name}已在列表中`,
                    type: 'error',
                    zIndex: 9999,
                    //showClose: true
                  })
                }
              }).catch((err) => {
                this.checkingCount--
                this.$emit('checkingCount', this.checkingCount)
                this.$message({
                  message: file.name + " " + err.message,
                  type: 'error',
                  zIndex: 9999,
                  //showClose: true
                })
              })
            }
          }).catch((err) => {
            this.checkingCount--
            this.$emit('checkingCount', this.checkingCount)
            console.warn(err)
            this.$message({
              message: file.name + " " + err.message,
              type: 'error',
              zIndex: 9999,
              //showClose: true
            })
          })
        }
        e.target.value = ''
      },
      async getFileId(file) {
        var partSize = this.partSize
        var firstPart = getFilePart(file, 0, partSize)
        var uid = this.currentUser.id
        if (!uid) {
          throw new Error('缺少用户信息');
        }
        var hash = await getmd5(firstPart)
        return md5ToGuid(md5(hash + file.name + file.size + file.lastModified + uid))
        //reader.readAsText(f);
        reader.readAsText(firstPart);
      },
      findFile(id) {
        for (var i in this.fileList) {
          if (this.fileList[i].id == id) {
            return this.fileList[i]
          }
        }
        return null
      },
      getSroteId() {
        var id = md5ToGuid(md5(`${this.action}${JSON.stringify(this.param)}`));
        return `file:${id}`
      },
      getPosition(id) {
        return this.$axios.get(`/api/Files/TempFileSize/${id}`).then(r => Number(r.data))
      },
      async checkFile(file) {
        if (this.beforeCheck) {
          if (typeof this.beforeCheck == 'function') {
            var r = this.beforeCheck(file)
            console.log('beforeCheck', r)
            if (r && r.then && r.catch) {
              r = await r
            }
            if (r == false) {
              return false
            }
          }
        }
        return await this.testFileName(file) && await this.testSize(file)
      },
      testFileName(file) {
        return new Promise((resolve, reject) => {
          if (this.allowExtensions) {
            if (!this.allowExtensions.test(file.name)) {
              return reject(new Error('不是有效的文件格式'))
            }
          }
          return resolve(file)
        })
      },
      testSize(file) {
        return new Promise((resolve, reject) => {
          if (!file.size) {
            return reject(new Error(`${file.name} 文件尺寸错误`))
          }
          if (this.maxSize && file.size > this.maxSize * 1000 * 1000) {
            return reject(new Error(`${file.name} 超过文件最大限制 ${this.maxSize} MB`))
          }
          if (this.minSize && file.size < this.minSize * 1000 * 1000) {
            return reject(new Error(`${file.name} 超过文件最小限制 ${this.minSize} MB`))
          }
          return resolve(file)
        })
      },
      submit() {
        for (var i = 0; i < this.fileList.length; i++) {
          if (!this.fileList[i].uploading && !this.fileList[i].ended) {
            this.waitingList.push(this.fileList[i].id)
          }
        }
        this.stratUpload()
      },
      remove(index) {
        var d = this.fileList[index]
        if (d.cancelToken) {
          d.cancelToken()
        }
        d.cancel = true;
        this.fileList.splice(index, 1)
      },
      stratUpload() {
        while (this.uping < this.maxUpper) {
          var id = this.waitingList.shift()
          let d = this.findFile(id)
          if (d) {
            var speedI = setInterval(() => {
              getSpeed(d)
              this.$emit('progress', d)
            }, 1000)
            this.Upload(d).then(this.stratUpload).then(() => clearInterval(speedI))
          } else {
            return
          }
        }
      },
      async Upload(d) {
        d.uploading = true
        d.status = 'updateing'
        d.cancel = false
        d.startLoadTime = new Date().getTime()
        d.startLoadPosition = d.position

        this.uping++
        try {
          while (!d.ended) {
            await this.Upload_OnePart(d)
            if (d.cancel) {
              d.status = 'cancel'
              return false
            }
          }
          this.uping--
          return true
        } catch (e) {
          console.error(e)
          d.status = 'failed'
          d.uploading = false
          d.err = e
          if (e?.response?.data) {
            d.errorMessage = e?.response?.data
          } else if (e?.response?.status > 299) {
            d.errorMessage = `错误代码 ${e?.response?.status}`
          }
          this.$emit('failed', d)
          this.uping--
          return false
        }
      },
      async Upload_OnePart(d) {
        //return new Promise((resolve, reject) => {
        var url = this.action;
        var partSize = this.partSize
        var file = getFilePart(d.file, d.position, partSize)
        if (file == null) {
          d.ended = true
          return
        }
        var range = `bytes ${d.position}-${d.position + file.size - 1}/${d.file.size}`;
        var CancelToken = this.$axios.CancelToken
        var hash = await getmd5(file)
        var ndata = new FormData();
        if (d.position + partSize > d.file.size) {
          ndata.append("isLast", "true");
        }
        ndata.append('file', file, d.name);
        ndata.append("name", d.name);
        ndata.append("hash", hash);
        for (var i in this.param) {
          ndata.append(i, this.param[i]);
        }
        var config = {
          cancelToken: new CancelToken((c) => {
            // 这个参数 c 就是CancelToken构造函数里面自带的取消请求的函数，这里把该函数当参数用
            d.cancelToken = c
          }),
          url: url,
          method: 'post',
          headers: {
            'X-File-Id': d.id,
            'Content-Type': 'multipart/form-data',
            "Content-Range": range
          },
          data: ndata,
          onUploadProgress: progressEvent => {
            var percent = ((d.position + progressEvent.loaded) / d.file.size * 100);
            if (percent > 100) {
              percent = 100;
            }
            d.percent = Number(percent.toFixed(2))
            d.loaded = d.position + progressEvent.loaded
            this.$emit('progress', d)
          }
        }
        var response = await this.$axios.request(config)
        //.then((response) => {
        d.position += file.size
        if (response.status == 202) {
          return d
          //resolve(d)
        } else if (response.status == 200) {
          d.ended = true
          d.response = response.data
          d.status = 'success'
          this.$emit('success', response.data)
          return d
          //resolve(d)
        } else {
          throw new Error(response.data)
          //  reject(response.data)
        }
        //})
        //.catch(function (error) {
        //  reject(error)
        //})
        //})
      },
    },
    watch: {
    }
  }
  function getmd5(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (e) => {
        var data = e.target.result;
        resolve(md5(data));
      };
      reader.onerror = (e) => {
        reject(e);
        console.log(e);
      };
      reader.readAsText(file);
    })
  }
  function getSpeed(d) {
    if (!d.lastLoadTime) {
      d.lastLoadTime = new Date().getTime()
      d.lastLoadPosition = d.loaded
      return
    }
    var nt = new Date().getTime()
    var dt = nt - d.lastLoadTime
    var totalTime = nt - d.startLoadTime
    if (dt > 1000) {
      var dp = d.loaded - d.lastLoadPosition
      var totalLoadData = d.loaded - d.startLoadPosition
      var s = dp / dt
      var aveSpeed = totalLoadData / totalTime
      d.speed = s * 1000
      d.aveSpeed = aveSpeed * 1000
      d.expectTime = ((d.size - d.loaded) / aveSpeed) | 0
      d.lastLoadTime = nt
      d.lastLoadPosition = d.loaded
    }
  }
  function md5ToGuid(md5) {
    return `${md5.slice(0, 8)}-${md5.slice(8, 12)}-${md5.slice(12, 16)}-${md5.slice(16, 20)}-${md5.slice(20)}`;
  }
  function getFilePart(file, startPosion, partSize) {
    if (startPosion >= file.size) {
      return null;
    } else if (startPosion + partSize > file.size) {
      return file.slice(startPosion);
    } else {
      return file.slice(startPosion, startPosion + partSize);
    }
  }
  function makePreview(file, maxWidth = 1000, maxHeight = 1000) {
    return new Promise((reasolve, reject) => {
      console.log(file);
      //读取图片数据
      var reader = new FileReader();
      reader.onload = function (e) {
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d");
        var data = e.target.result;
        //加载图片获取图片真实宽度和高度
        var image = new Image();
        image.onload = function () {
          var originWidth = image.width;
          var originHeight = image.height;

          // 目标尺寸
          var targetWidth = originWidth,
            targetHeight = originHeight;
          // 图片尺寸超过400x400的限制

          if (originWidth > maxWidth || originHeight > maxHeight) {
            if (originWidth / originHeight > maxWidth / maxHeight) {
              // 更宽，按照宽度限定尺寸
              targetWidth = maxWidth;
              targetHeight = Math.round(maxWidth * (originHeight / originWidth));
            } else {
              targetHeight = maxHeight;
              targetWidth = Math.round(maxHeight * (originWidth / originHeight));
            }
          }
          // canvas对图片进行缩放
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          // 清除画布
          context.clearRect(0, 0, targetWidth, targetHeight);
          // 图片压缩
          context.drawImage(image, 0, 0, targetWidth, targetHeight);
          reasolve({ url: canvas.toDataURL(file.type) });
          //var filename = file.name;
          // canvas.toBlob(
          //     function (blob) {
          //       console.log("toBlobEnd", new Date().getTime() - time);
          //       time = new Date().getTime();
          //       blob.name = filename;
          //       reasolve({ blob, url: canvas.toDataURL(file.type) });
          //     },
          //     file.type,
          //     0.9
          //   );
        };
        image.onerror = reject;
        image.src = data;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }
</script>

<style>
</style>
