<template>
  <div class="PanoLeafletMap">
    <client-only>
      <LeafletMap ref="map" :onMapReady="onMapReady" :mapConfig="mapConfig" :baseMaps="baseMaps" :ToolBars="ToolBars" :ToolBarsConfig="ToolBarsConfig"></LeafletMap>
    </client-only>
    <div class="drive">
      <slot name="buttons"></slot>
      <button v-if="navigationLoc" @click="toMap" style="line-height: 2rem;height: 2.5rem;">打开高德地图开始导航</button>
    </div>
    <div v-if="ToolBars" class="layerSelect" :style="(ToolBarsConfig.layerSelect||{}).style">
      <div v-for="l in layers">
        <a v-if="layersShow[l.name]" class="show" href="javascript:" @click="hideOverLayer(l.name)"><i class="far fa-check-square"></i> {{l.name}}</a>
        <a v-else class="hide" href="javascript:" @click="showOverLayer(l.name)"><i class="far fa-square"></i> {{l.name}}</a>
        <!--<label>
          <input type="checkbox" /> {{l.name}}
        </label>-->
      </div>
    </div>
    <AMapApiNavigation ref="Navigation"></AMapApiNavigation>
  </div>
</template>
<script>
  import Vue from 'vue'
  import LeafletMap from './LeafletMap'
  import AMapApiNavigation from './AMapApiNavigation'
  import MapPopupInfoPano from './MapPopupInfo-Pano'
  import MapPopupInfoTour from './MapPopupInfo-Tour'
  import gpsCorrect from '../../libs/gpsCorrect.js'
  import template from '../../libs/template.js'
  export default {
    scrollToTop: true,
    components: {
      LeafletMap,
      AMapApiNavigation,
    },
    props: {
      currentItem: Object,
      currentPanoView: Object,
      currentLoc: Object,
      currentItemConfig: {
        default: (event) => {
          console.log('props', event)
          return {
            key: 'key',
            popup: MapPopupInfoPano,
            popupConfig: {
              on: {
                导航: (e) => { window.navigation({ lat: e.gpsLat, lng: e.gpsLng, title: e.title }) }
              },
              width: '200px'
            },
          }
        }
      },
      ToolBars: {
        type: Boolean,
        default: true
      },
      ToolBarsConfig: {
        type: Object,
        default: () => {
          return {
          }
        }
      },
      baseMaps: {},
      mapCenter: Object,
      zoom: [String, Number],
      autoZoom: [Boolean, String],
      layers: {
        type: Array,
        default: () => [
          //{
          //  name: '客栈',
          //  url: '/Api/Inn/Inns/InnLocations',
          //},
          {
            name: '全景',
            url: '/Api/ES/Panos/Locations',
            geturl: process.env.EnableElasticSearch ? template`/Api/ES/Panos/${'key'}` : template`/Api/Panos/${'key'}`,
            popup: MapPopupInfoPano,
            popupConfig: {
              width: '200px',
              on: {
                导航: (e) => {
                  window.navigation({ lat: e.gpsLat, lng: e.gpsLng, title: e.title })
                }
              },
            },
            clusterer: true,
            params: {
              //category: '街景'
            }
          },
          {
            name: '漫游',
            url: '/Api/ES/Tours/Locations',
            geturl: process.env.EnableElasticSearch ? template`/Api/ES/Tours/${'key'}` : template`/Api/Tours/${'key'}`,
            popup: MapPopupInfoTour,
            popupConfig: {
              width: '200px',
              on: {
                导航: (e) => { window.navigation({ lat: e.gpsLat, lng: e.gpsLng, title: e.title }) }
              },
            },
            clusterer: true,
            params: {
              //category: '街景'
            }
          },
        ]
      },
      layer: {
        type: String,
        default: null,
      },
      url: {
        type: String,
        default: '/Api/Panos/PanoLocations'
      },
      params: Object,
    },
    data: () => {
      return {
        mapConfig: {
          //zoom: 1,
        },
        currentMarkEl: null,
        mapdata: null,
        navPoints: null,
        navigationLoc: null,
        layersData: {},
        layersQuery: {},
        layersState: {},
        layersShow: {},
      }
    },
    watch: {
      currentPanoView(val, oldval) {
        if (this.currentMarkEl) {
          this.updatePanoViewPie(this.currentMarkEl, val)
        }
      }
    },
    computed: {

    },
    created() {
      var zoom
      if (this.autoZoom) {
        zoom = 4
      } else if (typeof this.zoom == 'string') {
        if (this.zoom == 'min') {
          zoom = 3
        } else if (this.zoom == 'max') {
          zoom = 18
        } else if (Number(this.zoom)) {
          zoom = Number(this.zoom)
        }
      } else if (typeof this.zoom == 'number') {
        zoom = this.zoom
      }
      if (this.mapCenter) {
        this.mapConfig = {
          center: [this.mapCenter.lat, this.mapCenter.lng],
          zoom: zoom,
          layer: this.layer
        }
      } else {
        this.mapConfig = {
          zoom: zoom
        }
      }
    },
    mounted() {
      window.navigation = this.navigation
    },
    methods: {
      navigation(loc) {
        console.log(loc)
        this.$refs.Navigation.getGeoLocation().then((pStart) => {
          var pEnd = loc
          this.$refs.Navigation.navigation({ position: gpsCorrect.transform(pStart.lat, pStart.lng) }, { position: gpsCorrect.transform(pEnd.lat, pEnd.lng) }).then((res) => {
            this.navigationLoc = loc
            this.drowPolyline(res)
          }).catch(() => {
            alert('无法获取导航数据！')
          })
        }).catch(() => {
          alert('无法获取你的当前位置进行导航！')
        })
      },
      toMap() {
        var loc = this.navigationLoc
        this.$refs.Navigation.getGeoLocation().then((pStart) => {
          var pEnd = loc
          this.$refs.Navigation.urlNavigation({ position: gpsCorrect.transform(pStart.lat, pStart.lng), title: '我的位置' }, { position: gpsCorrect.transform(pEnd.lat, pEnd.lng), title: loc.title })
        })
      },
      flyTo(p, z) {
        this.$refs.map.flyTo(p.lat, p.lng, z)
      },
      drowPolyline(data) {
        var steps = data.data.route.paths[0].steps
        var points = []
        for (var i in steps) {
          points.push(...this.strToPoints(steps[i].polyline))
        }
        this.navPoints = points
        this.$refs.map.drowPolyline(points, null, 'navigation')
        this.$refs.map.lookAtPolyline('navigation')
      },
      strToPoints(str) {
        var points = []
        var ps = str.split(';')
        for (var i in ps) {
          var p = ps[i].split(',')

          //points.push({ lat: Number(p[1]), lng: Number(p[0]) })
          points.push(gpsCorrect.restore(Number(p[1]), Number(p[0])))
        }
        return points
      },
      renewPolyline() {
        this.$refs.map.drowPolyline(this.navPoints, null, 'navigation')
      },
      hideOverLayer(name) {
        this.$refs.map.hideOverLayer(name)
      },
      showOverLayer(name) {
        this.$refs.map.showOverLayer(name)
      },
      loadLayers() {
        this.renewLayers()
        for (var i in this.layers) {
          let l = this.layers[i]
          this.layersShow[l.name] = true
          let func = () => {
            var center = this.mapCenter || this.$refs.map.leafletMap.getCenter()
            if (!this.layersQuery[l.name]) {
              this.layersQuery[l.name] = {
                page: 1,
                pageSize: 1000,
                lng: center.lng || 0,
                lat: center.lat || 0,
                radius: 0,
                //max: 50000,
              }
            }
            this.layersState[l.name] = 'loading'
            this.$axios.get(l.url, {
              params: {
                ...this.layersQuery[l.name],
                ...l.params,
              }
            }).then((res) => {
              if (!this.layersData[l.name]) {
                this.layersData[l.name] = []
              }
              this.layersData[l.name].push(...res.data.data)
              if (res.data.totalRecords != 0) {
                this.appendMarks(l, res.data.data)
                if (res.data.totalRecords < res.data.pageIndex * res.data.pageSize || res.data.pageIndex * res.data.pageSize >= 10000) {
                  var p1 = this.layersQuery[l.name], p2 = res.data.data[res.data.data.length - 1]
                  var lat1 = p1.lat, lng1 = p1.lng, lat2 = p2.lat, lng2 = p2.lng
                  this.layersQuery[l.name].radius = gpsCorrect.getDistance(lat1, lng1, lat2, lng2) * 1000
                  if (this.layersQuery[l.name].radius == 0) {
                    this.layersState[l.name] = 'complete'
                    return
                  }
                  this.layersQuery[l.name].page = 1
                  setTimeout(func, 1000)
                } else {
                  this.layersQuery[l.name].page++
                  setTimeout(func, 1000)
                }
              } else {
                this.layersState[l.name] = 'complete'
              }
              //func()
            }).catch((err) => {
              console.error(err)
            })
          }
          func()
        }
      },
      onLayerComplete() {
        if (this.autoZoom) {
          this.autoZoomLayer()
          //this.autoZoomOut(center).then((Azoom) => {
          //  console.log(Azoom)
          //  //this.$refs.map.setCenterLatLng(center.lat, center.lng, { zoom: Azoom })
          //})
        } else {
        }
      },
      renewLayers() {
        for (var i in this.layers) {
          let l = this.layers[i]
          if (l.clusterer) {
            this.$refs.map.addMarkerClusterer(this.dataToMarkConfigs(this.layersData[l.name], l), {
              maxZoom: 20,
              on: {
                click: (event) => {
                  console.log(event)
                },
                add: () => {
                  console.log('add', l)
                  this.layersShow[l.name] = true
                  this.$forceUpdate()
                },
                remove: () => {
                  console.log('remove', l)
                  this.layersShow[l.name] = false
                  this.$forceUpdate()
                },
              }
            }, l.name, l.name)
          } else {
            this.$refs.map.addLayer(this.dataToMarkConfigs(this.layersData[l.name], l), {
              on: {
                click: (event) => {
                  console.log(event)
                },
                add: () => {
                  console.log('add', l)
                  this.layersShow[l.name] = true
                  this.$forceUpdate()
                },
                remove: () => {
                  console.log('remove', l)
                  this.layersShow[l.name] = false
                  this.$forceUpdate()
                },
              }
            }, l.name, l.name)
          }
        }
      },
      dataToMarkConfigs(data, layer) {
        var markconfigs = []
        for (var i in data) {
          var item = { ...data[i], ...layer.markconfig }
          if (this.currentItem && this.currentItemConfig.key && this.currentItem[this.currentItemConfig.key] == item[this.currentItemConfig.key]) {
            continue
          }
          if (item.icon) {
            item.icon = L.icon(item.icon)
          }
          var p = { lat: item.lat, lng: item.lng }
          //console.log(p, item)
          item.position = p
          item.on = {
            click: (event) => {
              this.$refs.map.addMarkerPopup(event.target, {
                content: `加载中…`,
              })
              var panoUrl = layer.geturl(event.target.extData)
              this.$axios.get(panoUrl).then((res) => {
                //var p = this.transformLoc(res.data.gpsLat, res.data.gpsLng)
                //console.log(p)
                this.$refs.map.addMarkerPopup(event.target, {
                  content: this.infoWindow(res.data, layer, event.target),
                })
              })
            }
          }
          item.extData = data[i]
          markconfigs.push(item)
        }
        return markconfigs
      },
      mapBackToPano() {
        if (this.currentLoc) {
          var zoom = this.$refs.map.getMaxZoom()
          this.$refs.map.setCenterLatLng(this.currentLoc.lat, this.currentLoc.lng, { zoom: zoom })
        }
      },
      addMapEvent(eName, func) {
        this.$refs.map.addMapEvent(eName, func)
      },
      autoZoomLayer() {
        var name = this.layers[0].name
        this.$refs.map.lookAtLayer(name)
      },
      autoZoomOut(center) {
        return new Promise((resolve, reject) => {
          var func = () => {
            if (this.autoZoom == 'AllPoints') {
              var latLngs = []
              for (let i in this.layersData) {
                let l = this.layersData[i]
                for (let li in l) {
                  let item = l[li]
                  latLngs.push(L.latLng(item.lat, item.lng))
                }
              }
              var bounds = L.latLngBounds(latLngs)
              this.$refs.map.leafletMap.flyToBounds(bounds)
              resolve(this.$refs.map.leafletMap.getBoundsZoom(bounds, true))
            } else {
              var Azoom = this.getPanoPointsZooms(center)
              this.$refs.map.setCenterLatLng(center.lat, center.lng, { zoom: Azoom })
              resolve(Azoom)
            }
          }
          func()
        })
        //if (this.$refs.map.bingMap.getZoom() == this.$refs.map.bingMap.getZoomRange().min) {
        //  return
        //}
        //var zoom = this.getPanoPointsZooms()
        //console.log(zoom)
      },
      getPanoPointsZooms(center) {
        console.log('getPanoPointsZooms', center)
        var { x: w, y: h } = this.$refs.map.leafletMap.getSize()
        var max = this.$refs.map.getMinZoom()
        var z = 1
        var c
        if (center) {
          c = L.latLng(center.lat, center.lng)
        } else {
          c = this.$refs.map.leafletMap.getCenter()
        }
        for (let i in this.layersData) {
          let l = this.layersData[i]
          for (let li in l) {
            let item = l[li]
            for (var j = this.$refs.map.getMaxZoom(); j >= this.$refs.map.getMinZoom(); j--) {
              var p = this.$refs.map.leafletMap.project(L.latLng(item.lat, item.lng), j)
              var pn = this.$refs.map.leafletMap.project(c, j)
              var x = p.x - pn.x
              var y = p.y - pn.y
              //if (p.x >= 0 && p.x <= w && p.y >= 0 && p.y <= h) {
              if (i == '11') {
              }
              if (x >= -w / 2 && x <= w / 2 && y >= -h / 2 && y <= h / 2) {
                if (j > max) {
                  max = j
                }
                break
              }
            }
          }
        }
        console.log(max)
        return max
      },
      getAllPanoPointsZooms(center) {
        var latLngs = []
        for (var i in this.panoPoints) {
          var item = this.panoPoints[i]
          latLngs.push(L.latLng(item.lat, item.lng))
        }
        var bounds = L.latLngBounds(latLngs)
        return this.$refs.map.leafletMap.getBoundsZoom(bounds, true)
      },
      getZoom() {
        var zoom
        if (typeof this.zoom == 'string') {
          if (this.zoom == 'min') {
            zoom = this.$refs.map.getMinZoom()
          } else if (this.zoom == 'max') {
            zoom = this.$refs.map.getMaxZoom()
          }
        } else if (typeof this.zoom == 'number') {
          zoom = this.zoom
        }
        return zoom
      },
      resetZoom(center) {
        console.log('resetZoom')
        var zoom
        if (typeof this.zoom == 'string') {
          if (this.zoom == 'min') {
            zoom = this.$refs.map.getMinZoom()
          } else if (this.zoom == 'max') {
            zoom = this.$refs.map.getMaxZoom()
          }
        } else if (typeof this.zoom == 'number') {
          zoom = this.zoom
        }
        console.log('resetZoom', zoom)
        this.$refs.map.setCenterLatLng(center.lat, center.lng, { zoom: zoom })
      },
      getCurrentPosition() {
        if (this.mapCenter) {
          //this.resetZoom(this.mapCenter)
          this.$refs.map.setCenterLatLng(this.mapCenter.lat, this.mapCenter.lng, { zoom: this.getZoom() })
          //this.$refs.map.getlocation()
          this.$refs.Navigation.getGeoLocation().then((pHere) => {
            this.$refs.map.addMark('me', {
              position: pHere,
              icon: L.icon({
                iconUrl: 'https://a.amap.com/jsapi_demos/static/resource/img/user.png',
                iconSize: [30, 30],
                iconAnchor: [15, 30],
                //popupAnchor: [-3, -76],
                //shadowUrl: 'my-icon-shadow.png',
                //shadowSize: [68, 95],
                //shadowAnchor: [22, 94]
              })
            })
          })
          this.loadLayers()
        } else {
          //this.$refs.map.getlocation().then((data) => {
          //  this.resetZoom(data)
          //}).catch((e) => {
          //  alert('无法获取当前设备位置信息，我们将无法定位附近全景！')
          //})
          this.$refs.Navigation.getGeoLocation().then((pHere) => {
            this.$refs.map.addMark('me', {
              position: pHere,
              icon: L.icon({
                iconUrl: 'https://a.amap.com/jsapi_demos/static/resource/img/user.png',
                iconSize: [30, 30],
                iconAnchor: [15, 30],
                //popupAnchor: [-3, -76],
                //shadowUrl: 'my-icon-shadow.png',
                //shadowSize: [68, 95],
                //shadowAnchor: [22, 94]
              })
            })
            this.resetZoom(pHere)
            this.loadLayers()
          }).catch((e) => {
            console.error(e)
            alert('无法获取当前设备位置信息，我们将无法定位附近全景！')
            this.loadLayers()
          })
        }
      },
      //transformLoc(lat, lng) {
      //  var p = gpsCorrect.transform(lat, lng)
      //  return p
      //  //return { lng: lng, lat: lat }
      //},
      getMapInfo() {
        var center = this.$refs.map.leafletMap.getCenter()
        var p = this.$refs.map.restoreLoc(center.lat, center.lng)
        return {
          map: 'leaflet',
          layer: this.$refs.map.getCurrentBaseLayer().name,
          zoom: this.$refs.map.leafletMap.getZoom(),
          lat: p.lat,
          lng: p.lng,
        }
      },
      setMapInfo(info) {
        this.$refs.map.setCenterLatLng(info.lat, info.lng, { zoom: info.zoom })
        this.$refs.map.changeBaseLayer(info.layer)
      },
      onMapReady(map) {
        //this.loadLayers()
        this.getCurrentPosition()

        if (this.panoPointsLoaded) {
          this.renewLayers()
        }
        this.loadThisMark()
        //if (this.mapCenter) {
        //  this.$refs.map.setCenterLngLat(this.mapCenter.lng, this.mapCenter.lat)
        //  this.resetZoom()
        //} else {
        //}
        //this.$refs.map.getlocation()
        L.DomEvent.on(this.$refs.map.leafletMap, 'baselayerchange', (e) => {
          this.$nextTick((e) => {
            this.mapTypeChanged(e)
          })
        }, this)
        this.$emit('MapReady', map)
      },
      mapTypeChanged(e) {
        this.renewLayers()
        this.loadThisMark()
        this.renewPolyline()
        this.$refs.map.getlocation()
        //this.getCurrentPosition()
      },
      loadThisMark() {
        if (this.currentLoc) {
          var p = { lat: this.currentLoc.lat, lng: this.currentLoc.lng }
          var icon
          if (this.currentPanoView) {
            this.currentMarkEl = this.drawPanoViewPie(this.currentPanoView, 110, 110)
            icon = L.divIcon({
              html: this.currentMarkEl,
              iconSize: [110, 110],
              className:'svgicon'
            //  iconAnchor: [9, 30],
            //  popupAnchor: [0, -30],
            })
          } else {
            icon = L.icon({
              iconUrl: 'https://webapi.aMap.com/theme/v1.3/markers/b/mark_rs.png',
              iconSize: [18, 30],
              iconAnchor: [9, 30],
              popupAnchor: [0, -30],
              //shadowUrl: 'my-icon-shadow.png',
              //shadowSize: [68, 95],
              //shadowAnchor: [22, 94]
            })
          }
          this.$refs.map.addMark('currentItem', {
            position: p,
            icon: icon,
            zIndexOffset: 10000,
            on: {
              click: (event) => {
                setTimeout(() => {
                  this.$refs.map.addMarkerPopup(event.target, {
                    content: this.infoWindow(this.currentItem, this.currentItemConfig, event.target),
                  })
                }, 1)
              }
            },
          })
        }
      },
      drawPanoViewPie(view, w, h) {
        var el = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        el.setAttribute("width", w);
        el.setAttribute("height", h);
        //el.style.position = 'absolute'
        //el.style.left = `${-w / 2}px`
        //el.style.top = `${-h / 2}px`

        var path = document.createElementNS("http://www.w3.org/2000/svg", "path");
        el.appendChild(path);
        path.setAttribute("stroke", '#81814c');
        path.setAttribute("stroke-width", 1);
        path.setAttribute("stroke-opacity", 0.75);
        path.setAttribute("fill", '#e5e5b8');
        path.setAttribute("fill-opacity", 0.5)

        this.updatePanoViewPie(el, view);
        return el
      },
      updatePanoViewPie(el, view) {
        if ((view?.fov ?? view?.hLookAt) == null) {
          return
        }
        var w = Number(el.getAttribute('width'))
        var h = Number(el.getAttribute('height'))
        var path = el.getElementsByTagName('path')[0]

        var gpsImgDirection = this.currentItem?.gpsImgDirection ?? 0
        var hl = this.math360(Number(view.hLookAt)) - gpsImgDirection
        var s = hl - view.fov / 2
        var e = hl + view.fov / 2
        var d = this.drawpie(w / 2, h / 2, Math.min(w, h) / 2 - 10, s, e)
        path.setAttribute("d", d)
        return el
      },
      math360(i, n) {
        //n ??= 180
        if (n == undefined) {
          n=180
        }
        while (i < n - 180 || i >= n + 180) {
          i = i > n ? i - 360 : i + 360
          i = Number(Number(i).toFixed(4))
        }
        return i
      },
      drawpie(//绘制扇形svg路径d
        /*x轴坐标*/  x,
        /*y轴坐标*/ y,
        /*半径*/ r,
        /*起始角度*/ sa,
        /*终点角度*/ ea
      ) {
        var g, f;
        sa > ea && (g = ea, ea = sa, sa = g);
        sa = sa * Math.PI / 180;
        ea = ea * Math.PI / 180;
        f = ea - sa;
        g = (sa + ea) / 2;
        var h = f > Math.PI ? 1 : 0;
        f >= 2 * Math.PI && (f = 2 * Math.PI - .01);
        sa = g - f / 2; ea = g + f / 2;
        g = x + r * Math.sin(sa);
        sa = y - r * Math.cos(sa);
        f = x + r * Math.sin(ea);
        ea = y - r * Math.cos(ea);
        return `M ${x},${y} L  ${g},${sa} A  ${r},${r} 0 ${h} 1 ${f},${ea} Z`
        //return "M " + x + "," + y + " L " + g + "," + sa + " A " + r + "," + r + " 0 " + h + " 1 " + f + "," + ea + " Z"
      },
      appendMarks(layer, data) {
        console.log(layer, data)
        this.$refs.map.appendMarker(this.dataToMarkConfigs(data, layer), layer.name,)
      },
      infoWindow(data, layer, marker) {
        var vm = new Vue(layer.popup || MapPopupInfoPano)
        vm.data = data
        //vm.marker = marker //nuxt使用会浏览器卡死
        var e = document.createElement('div')
        if (layer.popupConfig) {
          for (var i in layer.popupConfig.on) {
            console.log(i)
            vm.$on(i, layer.popupConfig.on[i])
          }
          e.style.width = layer.popupConfig.width || '200px'
        } else {
          e.style.width = '200px'
        }
        this.$nextTick(() => {
          vm.$mount(e)
        })
        return e
      },
      invalidateSize() {
        this.$refs.map.leafletMap.invalidateSize()
      }
    }
  }
</script>
<style lang="css" scoped>
  .PanoLeafletMap {
    width: 100%;
    height: 100%;
    position: relative
  }

    .PanoLeafletMap .drive {
      position: absolute;
      bottom: 35px;
      right: 80px;
      left: 80px;
      z-index: 1000;
      text-align: center;
    }

    .PanoLeafletMap .layerSelect {
      position: absolute;
      bottom: 35px;
      right: 10px;
      z-index: 1000;
      padding: 5px;
      border: 2px solid;
      border-radius: 4px;
      border-color: #c9c7c2;
      background-color: #ffffff;
      color: black;
      font-size: 14px;
    }

      .PanoLeafletMap .layerSelect .show {
      }

      .PanoLeafletMap .layerSelect .hide {
        background-color: #fff;
        color: #000;
      }
</style>
<style>
  .leaflet-div-icon .svgicon {
    background-color:transparent;
    border:none;
  }
</style>
