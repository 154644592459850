<template>
  <label :class="pclass?pclass:'btn btn-primary'">
    <slot>
      选择文件
    </slot>
    <input @change="addFile" :multiple="multiple" type="file" :name="name" :accept="inputAccetp" style="position:absolute; clip:rect(0 0 0 0);" />
  </label>

</template>

<script>
  import { uuid } from 'vue-uuid'
  import toGeoJSON from '@mapbox/togeojson/togeojson.js'
  export default {
    name: 'my-upload',
    props: {
      name: String,
      pclass: String,
      action: {
        type: String,
        required: true
      },
      fileList: {
        type: Array,
        default: []
      },
      param: Object,
      allowExtensions: RegExp,
      inputAccetp: String,
      multiple: Boolean,
      geoJson: Boolean,
      limit: Number,
      autoUpload: Boolean,
      maxSize: Number,
      maxWidth: Number,
      maxHeight: Number,
      compress: Boolean,
      isPano: Boolean,
      partSize: Number,
      surlheight: Number,
      surlwidth: Number,
      maxUpper: Number,
      onChange: Function,
      onBefore: Function,
      onProgress: Function,
      onSuccess: Function,
      onFailed: Function,
      onFinished: Function,
      onCheckingCount: Function,
    },
    data: function () {
      return {
        uping: 0,
        waitList: [],
        finish: true,
        checkingFileCount: 0,
        toGeoJSON: toGeoJSON
      };
    },
    methods: {
      addFile(e, option) {
        var files=[...e.target.files]
        //this.checkingFileCount += files.length;
        for (let i = files.length - 1; i >= 0; i--) {
          var limit = this.limit | 10
          var l = this.fileList.length;
          if ((l + this.checkingFileCount + 1) > limit) {
            this.$message({
              message: files[i].name + " 超过上传上限",
              type: 'error',
              zIndex: 9999,
              //showClose: true
            })
            continue;
          }
          this.checkingFileCount++;
          //if (this.checkingFileCount)
          this.onBeforeAdd(files[i]).then((file) => {
            this.addFileToList(file, option)
            this.checkingFileCount--;
          }).catch((err) => {
            this.$message({
              message: files[i].name + " " + err,
              type: 'error',
              zIndex: 9999,
              //showClose: true
            })
            this.checkingFileCount--;
            if (option && typeof option.onFailed == 'function') {
              option.onFailed(files[i], err)
            }
          })
        }
        e.target.value = ''
      },
      addFileToList(file, option) {
        file.url = URL.createObjectURL(file);
        file.status = 'ready';
        file.percent = 0;
        file.id = uuid.v1()
        if (option) {
          file.callback = option.onSuccess;
        }
        let fileList = [...this.fileList];
        if (this.multiple) {
          fileList = [...fileList, file];
        } else {
          fileList = [file];
        }
        this.fileList = fileList
        this.onChange(fileList);
      },
      onBeforeAdd(file) {
        return this.testFileName(file)
          .then(this.testMaxSize)
          .then(this.testWidthHeight)
          .then(this.compressFile)
          .then(this.transformCoordinate)
      },
      testFileName(file) {
        return new Promise((reasolve, reject) => {
          console.log(file.name, this.allowExtensions)
          if (this.allowExtensions) {
            if (!this.allowExtensions.test(file.name)) {
              reject('不是有效的文件格式')
              return;
            }
          }
          reasolve(file)
        })
      },
      transformCoordinate(file) {
        return new Promise((reasolve, reject) => {
          if (this.geoJson == true) {
            console.log(toGeoJSON)
            var reader = new FileReader();
            reader.onload = function (e) {
              var data = e.target.result;
              var html = document.createElement('html')
              html.innerHTML = data

              var xml, json, fileName
              if (/(.kml)$/i.test(file.name)) {
                xml = html.getElementsByTagName('kml')[0]
                json = toGeoJSON.kml(xml)
                fileName = file.name.substring(0, file.name.length - 4) + '.geojson'
              } else if (/(.gpx)$/i.test(file.name)) {
                xml = html.getElementsByTagName('gpx')[0]
                json = toGeoJSON.gpx(xml)
                fileName = file.name.substring(0, file.name.length - 4) + '.geojson'
              } else if (/(.geojson)$/i.test(file.name)) {
                reasolve(file)
                return
              } else {
                reject('无效格式')
              }
              console.log(json)
              const blob = new Blob([JSON.stringify(json)], {
                type: 'application/json'
              })
              blob.name = fileName
              console.log(blob)
              reasolve(blob)
            }
            reader.onerror = (err) => {
              reject('文件读取错误')
            }
            reader.readAsText(file);
            return
          }
          reasolve(file)
        })
      },
      testMaxSize(file) {
        return new Promise((reasolve, reject) => {
          var fileData = file
          if (this.maxSize) {
            var size = fileData.size;   //注意，这里读到的是字节数
            var isAllow = false;
            if (!size) isAllow = false;
            var max_size = this.maxSize
            max_size = max_size * 1024 * 1024;   //转化为字节
            isAllow = size <= max_size;
            if (!isAllow) {
              if (this.compress) {
                switch (file.type) {
                  case 'imgae/png':
                  case 'image/gif':
                    reject("超过文件大小限制 " + this.maxSize + "MB")
                    return;
                    break;
                  default:
                    fileData.needcompress = true;
                    //this.compressFile(file)
                    break;
                }
              } else {
                reject("超过文件大小限制 " + this.maxSize + "MB")
              }
            }
          }
          reasolve(fileData)
        })
      },
      testWidthHeight(file) {
        var self = this;
        return new Promise((reasolve, reject) => {
          if (!(self.isPano || self.maxWidth || self.maxHeight || self.compress || self.surlwidth || self.surlheight)) {
            reasolve(file);
            return
          }
          var isAllow = true;
          var fileData = file;
          //读取图片数据
          var reader = new FileReader();
          reader.onload = function (e) {
            if (e.loaded == 0) {
              reject('文件损坏')
              return
            }
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var data = e.target.result;
            //加载图片获取图片真实宽度和高度
            var image = new Image();
            image.onload = function () {
              var width = image.width;
              var height = image.height;
              if (self.isPano) {
                isAllow = isAllow ? width / height == 2 : false;
              }
              if (!isAllow) {
                reject("不是 2:1 柱面全景图片")
                return;
              }
              if (self.maxWidth) {
                isAllow = isAllow ? width <= self.maxWidth : false;
              }
              if (self.maxHeight) {
                isAllow = isAllow ? height <= self.maxHeight : false;
              }
              if (!isAllow) {
                if (self.compress) {
                  switch (fileData.type) {
                    case 'imgae/png':
                    case 'image/gif':
                      reject("超过图片尺寸限制 (" + self.maxWidth + "x" + self.maxHeight + ")")
                      return;
                      break;
                    default:
                      fileData.needcompress = true;
                      break;
                  }
                } else {
                  reject("超过图片尺寸限制 (" + self.maxWidth + "x" + self.maxHeight + ")")
                  return;
                }
              }
              if (self.surlwidth && self.surlheight) {
                var originWidth = this.width;
                var originHeight = this.height;

                // 最大尺寸限制
                var maxWidth = self.surlwidth, maxHeight = self.surlheight;
                // 目标尺寸
                var targetWidth = originWidth, targetHeight = originHeight;
                // 图片尺寸超过400x400的限制

                if (originWidth > maxWidth || originHeight > maxHeight) {
                  if (originWidth / originHeight > maxWidth / maxHeight) {
                    // 更宽，按照宽度限定尺寸
                    targetWidth = maxWidth;
                    targetHeight = Math.round(maxWidth * (originHeight / originWidth));
                  } else {
                    targetHeight = maxHeight;
                    targetWidth = Math.round(maxHeight * (originWidth / originHeight));
                  }
                }
                // canvas对图片进行缩放
                canvas.width = targetWidth;
                canvas.height = targetHeight;
                // 清除画布
                context.clearRect(0, 0, targetWidth, targetHeight);
                // 图片压缩
                context.drawImage(image, 0, 0, targetWidth, targetHeight);

                fileData.surl = canvas.toDataURL(fileData.type);
              }
              reasolve(fileData)
            }
            image.onerror = (err) => {
              reject('图片读取错误')
            }
            image.src = data;
          };
          reader.onerror = (err) => {
            reject('文件读取错误')
          }
          reader.readAsDataURL(fileData);
        })
      },
      remove(index) {
        let fileList = [...this.fileList];
        if (fileList.length) {
          if (fileList[index].status == "updateing") {
            var cancel = fileList[index].cancelToken;
            if (cancel) cancel('取消上传')
          }
          fileList.splice(index, 1);
          this.fileList = fileList
          this.onChange(fileList);
        }
      },
      submit() {
        if (this.checkIfCanUpload()) {
          this.finish = false;
          this.axiosSubmit();
        } else {
        }
      },
      axiosSubmit() {
        const _this = this;
        var filelist = this.fileList;
        for (var f in filelist) {
          this.addToWaitList(filelist[f])
        }
        this.stratUpload()
      },
      findFileByUuid(uuid) {
        for (var f in this.fileList) {
          if (this.fileList[f].id == uuid) {
            return this.fileList[f]
          }
        }
        return null;
      },
      checkIfCanUpload() {
        return this.fileList.length ? (this.onBefore && this.onBefore() || !this.onBefore) : false;
      },
      addToWaitList(file) {
        var _this = this;
        //"没有上传成功或者在上传"
        if (file.status == 'ready') {
          //"队列里没有此项"
          var has = false;
          for (var index in _this.waitList) {
            if (_this.waitList[index] == file.id) {
              has = true
            }
          }
          if (!has) {

            //this.waitList.push(file.id)
            _this.waitList = [..._this.waitList, file.id]

            let fileList = [..._this.fileList];
            for (var f in fileList) {
              if (fileList[f].id == file.id) {
                fileList[f].status = "wating";
                break;
              }
            }
            _this.fileList = fileList
            _this.onChange(fileList);
          }
        }
      },
      stratUpload() {
        var url = this.action;
        var _this = this;
        if (this.waitList.length > 0 && _this.uping < (_this.maxUpper ? _this.maxUpper : 3)) {
          var uuid = this.waitList.shift();
          var file = this.findFileByUuid(uuid)
          if (file) {
            _this.uping++
            let fileList = [..._this.fileList];
            for (var f in fileList) {
              if (fileList[f].id == uuid) {
                fileList[f].status = "updateing";
                break;
              }
            }
            _this.onChange(fileList);
            if (this.partSize) {

              var filearr = _this.cutFile(file, _this.partSize);
              _this.cutFile_upload(filearr, 0, file.size, _this.partSize, file.id)
            } else {
              let CancelToken = this.$axios.CancelToken
              let ndata = new FormData();

              ndata.append('file', file);
              for (var i in _this.param) {
                ndata.append(i, _this.param[i]);
              }
              var config = {
                cancelToken: new CancelToken(function executor(c) {
                  let fileList = [..._this.fileList];
                  for (var f in fileList) {
                    if (fileList[f].id == uuid) {
                      fileList[f].cancelToken = c;
                      break;
                    }
                  }
                  this.fileList = fileList
                  _this.onChange(fileList);
                  // 这个参数 c 就是CancelToken构造函数里面自带的取消请求的函数，这里把该函数当参数用
                }),
                url: url,
                method: 'post',
                headers: {
                  'X-File-Id': file.id
                },
                data: ndata,
                onUploadProgress: progressEvent => {
                  var percent = (progressEvent.loaded / progressEvent.total * 100 | 0);
                  if (percent > 100) {
                    percent = 100;
                  }
                  _this.onProgress(file.id, percent)
                }
              }
              this.$axios.request(config)
                .then(function (response) {
                  if (response.data.status === 'success') {
                    _this.fileSuccess(uuid, response.data)
                    if (_this.waitList.length == 0 && !_this.finish) {
                      _this.finish = true;
                      if (_this.onFinished) _this.onFinished()
                    }
                    _this.stratUpload();

                  } else {
                    let fileList = [..._this.fileList];
                    for (var f in fileList) {
                      if (fileList[f].id == uuid) {
                        fileList[f].status = "Failed";
                        break;
                      }
                    }
                    _this.uping--
                    _this.fileList = fileList
                    _this.onChange(fileList);

                    _this.onFailed(uuid, response);
                  }
                })
                .catch(function (error) {
                  console.log(error)
                  console.log({ ...error.data })
                  _this.uping--
                  if (error.message == '取消上传') {
                    console.log("上传已取消")
                  } else {
                    let fileList = [..._this.fileList];
                    for (var f in fileList) {
                      if (fileList[f].id == uuid) {
                        fileList[f].status = "Failed";
                        fileList[f].errorMessage = error.response.data;
                        break;
                      }
                    }
                    _this.fileList = fileList
                    _this.onChange(fileList);
                  }
                })
            }
          }
          this.stratUpload();
        }
      },
      //切割大文件
      cutFile: function (file, cutSize) {
        var count = file.size / cutSize | 0, fileArr = [];
        var filen;
        for (var i = 0; i < count; i++) {
          filen = file.slice(cutSize * i, cutSize * (i + 1));
          fileArr.push({
            name: file.name,
            file: filen,
          });
        }
        filen = file.slice(cutSize * count, file.size);
        fileArr.push({
          name: file.name,
          file: filen,

        });
        return fileArr;
      },
      cutFile_upload: function (fileArr, count, fileSize, cutSize, rawFileid) {
        var url = this.action;

        //img.loader="jz";
        var _this = this;
        var formData = new FormData();
        if (count == undefined) {
          count = 0;
        }
        if (count === fileArr.length - 1) {
          formData.append("isLast", "true");
        }
        formData.append("count", count);
        formData.append("name", fileArr[count].name);
        formData.append("file", fileArr[count].file, fileArr[count].name);
        for (var i in _this.param) {
          formData.append(i, _this.param[i]);
        }

        var thisSize = fileArr[count].file.size;
        var range = "bytes " + (cutSize * count) + "-" + (cutSize * count + thisSize - 1) + "/" + fileSize;
        var uuid = rawFileid
        let CancelToken = this.$axios.CancelToken
        var config = {
          url: url,
          method: 'post',
          headers: {
            'X-File-Id': rawFileid,
            'Content-Type': 'multipart/form-data',
            "Content-Range": range
          },
          data: formData,

          onUploadProgress: progressEvent => {
            var percent = ((cutSize * count + progressEvent.loaded) / fileSize * 100 | 0);
            if (percent > 100) {
              percent = 100;
            }
            this.onProgress(rawFileid, percent)
          },
          cancelToken: new CancelToken(function executor(c) {
            let fileList = [..._this.fileList];
            for (var f in fileList) {
              if (fileList[f].id == uuid) {
                fileList[f].cancelToken = c;
                break;
              }
            }
            _this.fileList = fileList
            _this.onChange(fileList);
            // 这个参数 c 就是CancelToken构造函数里面自带的取消请求的函数，这里把该函数当参数用
          }),
        };
        this.$axios.request(config)
          .then(function (response) {
            if (response.status == 202) {
              var currCount = count + 1;
              _this.cutFile_upload(fileArr, currCount, fileSize, cutSize, rawFileid);
            } else if (response.status == 200) {

              _this.fileSuccess(uuid, response.data)
              if (_this.waitList.length == 0 && !_this.finish) {
                _this.finish = true;
                if (_this.onFinished) _this.onFinished()
              }
              _this.stratUpload();
            }
            else {
              let fileList = [..._this.fileList];
              for (var f in fileList) {
                if (fileList[f].id == uuid) {
                  fileList[f].status = "Failed";
                  break;
                }
              }
              _this.uping--

              _this.fileList = fileList
              _this.onChange(fileList);
              _this.onFailed(uuid, response);
            }
          })
          .catch(function (error) {
            console.log(error)
            _this.uping--
            if (error.message == '取消上传') {
              console.log("上传已取消")
            } else {
              let fileList = [..._this.fileList];
              for (var f in fileList) {
                if (fileList[f].id == uuid) {
                  fileList[f].status = "Failed";
                  fileList[f].errorMessage = error.response.data;
                  break;
                }
              }
              _this.fileList = fileList
              _this.onChange(fileList);
            }
          })
      },
      fileSuccess(uuid, response) {
        let fileList = [...this.fileList];
        for (var f in fileList) {
          if (fileList[f].id == uuid) {
            fileList[f].status = "success";
            if (typeof fileList[f].callback == 'function') {
              fileList[f].callback(response)
            }
            break;
          }
        }
        this.uping--
        this.fileList = fileList
        this.onChange(fileList);
        this.onSuccess(uuid, response);

      },
      compressFile(file) {
        return new Promise((reasolve, reject) => {
          if (!file.needcompress) {
            reasolve(file)
            return
          }
          var self = this;
          console.log(file);
          //读取图片数据
          var reader = new FileReader();
          reader.onload = function (e) {
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var data = e.target.result;
            //加载图片获取图片真实宽度和高度
            var image = new Image();
            image.onload = function () {
              var width = image.width;
              var height = image.height;
              var originWidth = this.width;
              var originHeight = this.height;
              var surl
              if (self.surlwidth && self.surlheight) {
                var originWidth = this.width;
                var originHeight = this.height;

                // 最大尺寸限制
                var maxWidth = self.surlwidth, maxHeight = self.surlheight;
                // 目标尺寸
                var targetWidth = originWidth, targetHeight = originHeight;
                // 图片尺寸超过400x400的限制

                if (originWidth > maxWidth || originHeight > maxHeight) {
                  if (originWidth / originHeight > maxWidth / maxHeight) {
                    // 更宽，按照宽度限定尺寸
                    targetWidth = maxWidth;
                    targetHeight = Math.round(maxWidth * (originHeight / originWidth));
                  } else {
                    targetHeight = maxHeight;
                    targetWidth = Math.round(maxHeight * (originWidth / originHeight));
                  }
                }
                // canvas对图片进行缩放
                canvas.width = targetWidth;
                canvas.height = targetHeight;
                // 清除画布
                context.clearRect(0, 0, targetWidth, targetHeight);
                // 图片压缩
                context.drawImage(image, 0, 0, targetWidth, targetHeight);

                surl = canvas.toDataURL(file.type);
              }
              // 最大尺寸限制
              var maxWidth = self.maxWidth, maxHeight = self.maxHeight;
              // 目标尺寸
              var targetWidth = originWidth, targetHeight = originHeight;
              // 图片尺寸超过400x400的限制

              if (originWidth > maxWidth || originHeight > maxHeight) {
                if (originWidth / originHeight > maxWidth / maxHeight) {
                  // 更宽，按照宽度限定尺寸
                  targetWidth = maxWidth;
                  targetHeight = Math.round(maxWidth * (originHeight / originWidth));
                } else {
                  targetHeight = maxHeight;
                  targetWidth = Math.round(maxHeight * (originWidth / originHeight));
                }
              }
              // canvas对图片进行缩放
              canvas.width = targetWidth;
              canvas.height = targetHeight;
              // 清除画布
              context.clearRect(0, 0, targetWidth, targetHeight);
              // 图片压缩
              context.drawImage(image, 0, 0, targetWidth, targetHeight);
              var filename = file.name;
              canvas.toBlob(function (blob) {
                blob.surl = surl;
                blob.name = filename;
                self.$message({
                  message: blob.name + " 已压缩为 (" + targetWidth + "x" + targetHeight + ") " + Math.floor(blob.size / 1024) + ' KB',
                  type: 'info',
                  zIndex: 9999,
                  //showClose: true
                })
                reasolve(blob)
                //self.addFileToList(blob)
              }, file.type, 0.9)
            }
            image.src = data;
          };
          reader.readAsDataURL(file);
        })
      }
    },
    watch: {
      checkingFileCount: function (val, oldval) {
        if (this.onCheckingCount) {
          this.onCheckingCount(val)
        }
      },
      fileList: function (val, oldval) {
        if (this.autoUpload && val.length > 0) {
          this.submit();
        }
      }
    }

  }
</script>

<style>
</style>
