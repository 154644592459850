<template>
  <div
    class="video360"
    :class="{ grabbing }"
    ref="el"
    @mousedown="mousedown"
    @mousemove="mousemove"
    @mouseup="mouseup"
    @mousewheel.stop.prevent="mousewheel"
    @touchstart.stop="touchstart"
    @touchmove.stop="touchmove"
    @touchend.stop="touchend"
  >
    <canvas
      class="video"
      ref="canvas"
      :style="{
        transform: transformString,
      }"
    ></canvas>
    <div class="round">{{ ((currentTime / duration || 0) * 360).toFixed(0) }}°</div>
  </div>
</template>
<script>
import GLChroma from "../libs/glChromakey/index";
export default {
  props: {
    url: {},
    poster: {},
    maxScale: {
      default: 3,
    },
    minScale: {
      default: 1,
    },
    touchScale: {
      default: 0.005,
    },
    wheelScale: {
      default: -0.0005,
    },
    roundScale: {
      default: 0.0025,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    chromaKey: {},
    tolerance: {
      default: 0.3,
    },
    amount: {
      default: 1.0,
    },
    autoStartTime: {
      default: 1500,
    },
    autoWaitTime: {
      default: 5000,
    },
  },
  data() {
    return {
      down: false,
      grabbing: false,
      moveTime: 0,
      timer: null,
      currentTime: 0,
      duration: 0,
      touch: null,
      seeked: true,
      waitAutoRound: null,
      AutoRound: null,
      msg: null,
      video: null,
      gLChroma: null,
      frameId: null,
      scale: 1,
      translateX: 0,
      translateY: 0,
      touchsDistance: null,
      touchsCenterX: null,
      touchsCenterY: null,
      bgurl: null,
      longTouchTimer: null,
      longTouchDistance: 0,
      longTouch: false,
      clickTimer: null,
      DclickTimer: null,
      DclickTimes: 0,
      touchendCount: 0,
      touched: false,
      videoShow: false,
    };
  },
  mounted() {
    this.video = document.createElement("video");
    this.video.crossOrigin = "";
    this.video.preload = "auto";
    this.video.volume = 0;
    // this.video.className = "bg";

    // this.video.disablepictureinpicture = true;
    // this.video.disableRemotePlayback = true;
    this.video.setAttribute("playsinline", "");
    // this.video.setAttribute("webkit-playsinline", "");
    this.video.setAttribute("muted", "");
    this.video.setAttribute("loop", "");
    this.video.setAttribute("autoplay", "");

    this.video.addEventListener("seeked", this.onSeeked);
    this.video.addEventListener("canplay", this.onCanplay);
    this.video.addEventListener("play", this.onPlay);
    this.video.addEventListener("timeupdate", this.ontimeupdate);
    this.video.addEventListener("complete", this.onComplete);

    //this.startChroma();
    if (this.url) this.video.src = this.url;
    if (this.poster) this.video.poster = this.poster;
    // this.startWait(3000);
  },
  computed: {
    transformString() {
      return `scale(${this.scale}) translate(${this.translateX}px, ${this.translateY}px)`;
    },
  },
  updated() {
    this.video.style.transform = this.transformString;
  },
  watch: {
    url(val) {
      this.video.src = val;
      this.video.addEventListener("canplay", this.onCanplay);
    },
    poster(val) {
      if (val) this.video.poster = val;
    },
    chromaKey(val) {
      setTimeout(this.setChromaKey);
    },
    tolerance(val) {
      setTimeout(this.setChromaKey);
    },
    amount(val) {
      setTimeout(this.setChromaKey);
    },
  },
  methods: {
    startRound() {
      try {
        //if (this.$refs.el.offsetHeight < 300) {
        //  if (this.video.videoWidth > this.$refs.el.offsetWidth) {
        //    this.$refs.canvas.width = this.$refs.el.offsetWidth
        //    this.$refs.canvas.height = this.video.videoHeight * (this.$refs.el.offsetWidth / this.$refs.el.offsetHeight)
        //  } else {
        //    this.$refs.canvas.width = this.video.videoWidth;
        //    this.$refs.canvas.height = this.video.videoHeight;
        //  }
        //} else {
        var { width, height } = this.getSize(
          this.video.videoWidth,
          this.video.videoHeight,
          this.$refs.el.offsetWidth,
          this.$refs.el.offsetHeight
        );
        this.$refs.canvas.width = width;
        this.$refs.canvas.height = height;
        //}
        // this.msg=JSON.stringify({video:this.video,canvas:this.$refs.canvas})
        this.gLChroma = new GLChroma(this.video, this.$refs.canvas);
        this.setChromaKey();
        if (testVideo(this.video)) {
          this.startChroma();
        } else {
          this.videoShow = true;
          this.video.style.position = "absolute";
          this.video.style.left = 0;
          this.video.style.top = 0;
          this.video.style.right = 0;
          this.video.style.bottom = 0;
          this.video.style.width = "100%";
          this.video.style.height = "100%";
          // this.$refs.el.appendChild(this.video);
          this.$refs.canvas.before(this.video)
        }
      } catch (error) {
        this.msg = error.message;
        console.error(error);
      }
    },
    getSize(ow, oh, tw, th) {
      //获得最大容纳尺寸
      var t = ow / oh < tw / th ? th / oh : tw / ow; //确定最大系数
      //console.log({ w: ow * t, h: oh * t }, { ow, oh, tw, th })
      return { width: ow * t, height: oh * t };
    },
    setChromaKey() {
      if (this.gLChroma)
        if (this.chromaKey) {
          var c = [
            Number("0x" + this.chromaKey.slice(1, 3)),
            Number("0x" + this.chromaKey.slice(3, 5)),
            Number("0x" + this.chromaKey.slice(5, 7)),
          ];
          this.gLChroma.key({
            color: c,
            tolerance: this.tolerance,
            amount: this.amount,
          });
        } else {
          this.gLChroma.key();
        }
      else alert("miss gLChroma");
    },
    mousedown(e) {
      if (this.touched) return;
      this.start();
      this.grabbing = true;
      this.longTouchDistance = 0;
      this.longTouchTimer = setTimeout(() => {
        this.longTouch = true;
        this.longTouchTimer = null;
      }, 300);
      if (!this.DclickTimer)
        this.DclickTimer = setTimeout(() => {
          this.DclickTimer = null;
          this.DclickTimes = 0;
        }, 300);
    },
    mousemove(e) {
      if (this.touched) return;
      this.longTouchDistance += Math.sqrt(
        Math.pow(e.movementX, 2) + Math.pow(e.movementY, 2)
      );
      if (this.longTouch) {
        this.translateX += e.movementX * (1 / this.scale);
        this.translateY += e.movementY * (1 / this.scale);
      } else {
        this.move(e.movementX);
        if (this.longTouchDistance > 10) {
          clearTimeout(this.longTouchTimer);
        }
      }
    },
    mouseup(e) {
      if (this.touched) return;
      this.end();
      this.grabbing = false;
      if (this.DclickTimer && this.longTouchDistance < 10) {
        this.DclickTimes++;
      }
      if (this.DclickTimes == 2) {
        this.msg = "双击";
        this.doubleClicks();
      }
      clearTimeout(this.longTouchTimer);
      this.longTouch = false;
    },
    mousewheel(e) {
      console.log(e.clientX - this.$refs.el.clientLeft, e);

      var x = this.$refs.el.offsetWidth / 2 - (e.clientX - this.$refs.el.clientLeft);
      var y = this.$refs.el.offsetHeight / 2 - (e.clientY - this.$refs.el.clientTop);
      this.setScale(e.deltaY * this.wheelScale, x, y);
      // this.scale *= 1 + e.deltaY * this.wheelScale;
      // if (this.scale < this.minScale) {
      //   this.scale = this.minScale;
      // }
      // if (this.scale > this.maxScale) {
      //   this.scale = this.maxScale;
      // }
      // console.log(e);
    },
    touchstart(e) {
      this.touched = true;
      this.start();
      console.log(e);
      this.msg = "touchstart";
      this.touch = e.changedTouches[0];
      if (e.touches.length == 1) {
        this.longTouchDistance = 0;
        this.longTouchTimer = setTimeout(() => {
          this.longTouch = true;
          this.longTouchTimer = null;
        }, 300);
        if (!this.DclickTimer)
          this.DclickTimer = setTimeout(() => {
            this.DclickTimer = null;
            this.DclickTimes = 0;
          }, 300);
      }
      if (e.touches.length > 1) {
        clearTimeout(this.longTouchTimer);
        clearTimeout(this.DclickTimer);
        this.DclickTimer = null;
        this.DclickTimes = 0;
        this.end();
        try {
          var t1 = e.touches[0];
          var t2 = e.touches[1];
          this.touchsDistance = Math.sqrt(
            Math.pow(t1.clientX - t2.clientX, 2) + Math.pow(t1.clientY - t2.clientY, 2)
          );
          this.touchsCenterX = (t1.clientX + t2.clientX) / 2;
          this.touchsCenterY = (t1.clientY + t2.clientY) / 2;
        } catch (e) {
          this.msg = e.message;
        }
      }
      this.video.play();
      this.video.pause();
    },
    touchmove(e) {
      this.msg = "touchmove";
      var _touch = e.changedTouches[0];
      this.longTouchDistance += Math.sqrt(
        Math.pow(_touch.clientX - this.touch.clientX, 2) +
          Math.pow(_touch.clientY - this.touch.clientY, 2)
      );
      if (this.longTouch) {
        this.translateX += (_touch.clientX - this.touch.clientX) * (1 / this.scale);
        this.translateY += (_touch.clientY - this.touch.clientY) * (1 / this.scale);
      } else {
        this.move(_touch.clientX - this.touch.clientX);

        if (this.longTouchDistance > 10) {
          clearTimeout(this.longTouchTimer);
        }
      }
      this.touch = _touch;
      if (this.touchsDistance && e.touches.length > 1) {
        var t1 = e.touches[0];
        var t2 = e.touches[1];
        var d = Math.sqrt(
          Math.pow(t1.clientX - t2.clientX, 2) + Math.pow(t1.clientY - t2.clientY, 2)
        );
        this.scale *= 1 + (d - this.touchsDistance) * this.touchScale;
        if (this.scale < this.minScale) {
          this.scale = this.minScale;
        }
        if (this.scale > this.maxScale) {
          this.scale = this.maxScale;
        }
        this.touchsDistance = d;
        var tx = (t1.clientX + t2.clientX) / 2;
        var ty = (t1.clientY + t2.clientY) / 2;
        this.translateX += (tx - this.touchsCenterX) * (1 / this.scale);
        this.translateY += (ty - this.touchsCenterY) * (1 / this.scale);
        this.touchsCenterX = tx;
        this.touchsCenterY = ty;
      }
    },
    touchend(e) {
      this.touchendCount++;
      this.end();
      this.msg = "touchend";
      this.touch = null;
      if (this.DclickTimer && this.longTouchDistance < 10) {
        this.DclickTimes++;
      }
      if (this.DclickTimes == 2) {
        this.msg = "双击";
        this.doubleClicks();
      }
      clearTimeout(this.longTouchTimer);
      this.longTouch = false;
      if (!(this.touchsDistance && e.touches.length > 1)) {
        this.touchsDistance = null;
      }
    },
    setScale(d, x, y) {
      var sy = this.scale;
      this.scale *= 1 + d;
      if (this.scale < this.minScale) {
        d = this.minScale / sy - 1;
        this.scale = this.minScale;
      }
      if (this.scale > this.maxScale) {
        d = this.maxScale / sy - 1;
        this.scale = this.maxScale;
      }
      var k = this.scale / sy - 1;
      // if (k > 0) {
      this.translateX += x * k;
      this.translateY += y * k;
      // } else {
      //   this.translateX += k;
      //   this.translateY += k;
      // }
    },
    doubleClicks() {
      this.msg = this.scale;
      if (this.scale > (this.minScale + this.maxScale) / 2) {
        this.scale = this.minScale;
        this.translateX = 0;
        this.translateY = 0;
      } else {
        this.scale = this.maxScale;
      }
    },
    start() {
      this.stopAutoRound();
      this.down = true;
    },
    move(x) {
      if (this.down) {
        this.round(x);
      }
    },
    end() {
      this.down = false;
      this.startWait(this.autoWaitTime);
    },
    round(x) {
      // this.video.seek(this.video.currentTime + e.movementX);
      this.moveTime +=
        x * this.roundScale * (this.reverse ? -1 : 1) * this.video.duration;
      // if (this.timer == null) {
      if (this.seeked) {
        if (this.video.currentTime + this.moveTime > this.video.duration) {
          this.video.currentTime =
            this.video.currentTime + this.moveTime - this.video.duration;
        } else if (this.video.currentTime + this.moveTime < 0) {
          this.video.currentTime =
            this.video.currentTime + this.moveTime + this.video.duration;
        } else {
          this.video.currentTime += this.moveTime;
        }
        // console.log(this.moveTime);
        // this.currentTime = this.video.currentTime;
        // this.duration = this.video.duration;
        this.moveTime = 0;
        // this.timer = setTimeout(() => {
        //   this.timer = null;
        // }, 60);
        this.seeked = false;
      }
    },
    onSeeked() {
      this.seeked = true;
      if (this.videoShow) {
        if (testVideo(this.video)) {
          this.videoShow = false;
          this.startChroma();
          this.video.remove();
          // this.gLChroma = new GLChroma(this.video, this.$refs.canvas);
          // this.setChromaKey();
          // this.drawVideo();
        }
      }
    },
    onCanplay() {
      this.startRound();

      console.log("canplay");
      this.video.removeEventListener("canplay", this.onCanplay);
      this.startWait(this.autoStartTime);
    },
    onPlay(e) {
      console.log("onPlay", e);
      // this.drawVideo();
    },
    ontimeupdate(e) {
      this.currentTime = e.target.currentTime;
      this.duration = e.target.duration;
    },
    onComplete(e) {
      console.log(e);
    },
    startChroma() {
      this.frameId = requestAnimationFrame(this.startChroma);
      this.gLChroma.render();
      // this.drawVideo();
    },
    stopChroma() {
      cancelAnimationFrame(this.frameId);
    },
    startWait(t) {
      if (!this.waitAutoRound) this.waitAutoRound = setTimeout(this.startAutoRound, t);
    },
    stopWait() {
      clearTimeout(this.waitAutoRound);
      this.waitAutoRound = null;
    },
    startAutoRound() {
      this.waitAutoRound = null;
      this.video
        .play()
        .then(() => {
          console.log("played");
        })
        .catch((e) => {
          console.log(e);
          if (this.AutoRound) clearInterval(this.AutoRound);
          this.AutoRound = setInterval(() => {
            this.round(5);
          }, 50);
        });
    },
    stopAutoRound() {
      this.stopWait();
      clearInterval(this.AutoRound);
      this.video.pause();
    },
    async drawVideo() {
      const video = this.video;
      const canvas = this.$refs.canvas;
      var ctx = canvas.getContext("2d");
      if (video.videoWidth != 0) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.fillStyle = "#f00";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        console.log(canvas.toDataURL());
      } else {
        if (this.poster) {
          var img = await loadImg(this.poster);
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        }
      }
      this.bgurl = canvas.toDataURL();
    },
  },
};
function loadImg(src) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.src = src;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (err) => {
      reject(err);
    };
  });
}
function testVideo(video) {
  var canvas = document.createElement("canvas");
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  var ctx = canvas.getContext("2d");
  ctx.fillStyle = "#0000";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  var d = ctx.getImageData(0, 0, canvas.width, canvas.height);
  for (var i = 0; i < d.data.length; i++) {
    if (d.data[i] != 0) {
      return true;
    }
  }
  console.log("video无法读取数据");
  return false;
}
</script>
<style scoped>
.video360 {
  position: relative;
  overflow: hidden;
  text-align: center;
  cursor: grab;
  user-select: none;
}

.video360.grabbing {
  cursor: grabbing;
}

.round {
  position: absolute;
  left: 5px;
  right: 5px;
  top: 5px;
  text-align: center;
  pointer-events: none;
  color: white;
  text-shadow: 0 0 5px black;
  z-index: 1;
}

</style>
